import "../styles/terms.scss";
import bg_mobile from "../assets/terms/bg-mobile.png";
import privacybg_mobile from "../assets/terms/bg.png";
import logo from "../assets/terms/logo-mobile.png";
import { useEffect, useState } from "react";
import mainLogo from "../assets/logos/logo_light.png";

const Policy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="terms-container bg-white">
      <div className="header">
        {/* Conditional rendering based on screen size */}
        {window.innerWidth > 500 ? (
          <>
            <header className="lg:flex py-2 px-10 text-white text-center shadow-lg border border-b-gray-300">
              <div className="flex items-center justify-center">
                <img src={mainLogo} width={200} height={200} alt="logo" />
              </div>
            </header>
            <img
              src={privacybg_mobile}
              alt="Background"
              className="max-h-[30vh] shadow-md"
            />
            <div className="img-text">
              <h1>Private Policy</h1>
              <p className="sub-heading">Have questions? We're here to help.</p>
            </div>
          </>
        ) : (
          <img src={bg_mobile} alt="Mobile Background" />
        )}

        {/* Conditional rendering of logo */}
        {window.innerWidth <= 500 && (
          <>
            <img src={logo} alt="Logo" className="logo" />
            <div className="img-text">
              <h1>Support</h1>
              <p className="sub-heading">Have questions? We're here to help.</p>
            </div>
          </>
        )}
      </div>
      <div className="body">
        <p className="description">
          Thank you for visiting our website. At Finberry, we value your privacy and want to ensure that you understand
          how we collect, use, and protect your personal information. This
          Privacy Policy outlines the types of personal information we collect,
          how we use it, and the steps we take to protect it.
        </p>
        <div className="section">
          <h2>Information We Collect</h2>
          <p className="description">
            We may collect personal information from you when you visit our
            website or fill out a form, including but not limited to your name,
            email address, mailing address, phone number, and payment
            information. We may also collect non-personal information, such as
            your IP address, browser type, and operating system.
          </p>
        </div>

        <div className="section">
          <h2>How We Use Your Information</h2>
          <p className="description">
            We use your personal information to provide you with the products
            and services you request, to process your payments, to communicate
            with you, and to improve our website and services. We may also use
            your information to send you marketing communications about our
            products and services, but you can opt-out of these communications
            at any time.
          </p>
        </div>

        <div className="section">
          <h2>How We Protect Your Information</h2>
          <p className="description">
            We take the security of your personal information seriously and have
            implemented measures to protect it from unauthorized access, use, or
            disclosure. We use industry-standard encryption technologies and
            secure servers to protect your information. However, no method of
            transmission over the Internet or electronic storage is 100% secure,
            so we cannot guarantee its absolute security.
          </p>
        </div>

        <div className="section">
          <h2>Sharing Your Information</h2>
          <p className="description">
            We do not sell, trade, or rent your personal information to third
            parties. However, we may share your information with trusted
            third-party service providers who assist us in operating our
            website, conducting our business, or providing you with services. We
            require these service providers to keep your information
            confidential and to only use it for the purposes for which we have
            contracted them.
          </p>
        </div>

        <div className="section">
          <h2>Cookies and Other Technologies</h2>
          <p className="description">
            Our website may use cookies, web beacons, and other technologies to
            collect information about your use of our website and to personalize
            your experience. You can control the use of cookies at the
            individual browser level, but if you choose to disable cookies, it
            may limit your use of certain features or functions on our website.
          </p>
        </div>

        <div className="section">
          <h2>Links to Other Websites</h2>
          <p className="description">
            Our website may contain links to other websites that are not
            operated by us. We are not responsible for the privacy practices of
            these websites and encourage you to review their privacy policies
            before providing any personal information.
          </p>
        </div>

        <div className="section">
          <h2>Children's Privacy</h2>
          <p className="description">
            Our website is not directed to children under the age of 13, and we
            do not knowingly collect personal information from children under
            the age of 13. If we become aware that we have collected personal
            information from a child under the age of 13, we will take steps to
            delete it as soon as possible.
          </p>
        </div>

        <div className="section">
          <h2>Changes to this Privacy Policy</h2>
          <p className="description">
            We reserve the right to change this Privacy Policy at any time. We
            will notify you of any changes by posting the new Privacy Policy on
            this page with an updated date. We encourage you to review this
            Privacy Policy periodically to stay informed about our information
            practices.
          </p>
        </div>

        <div className="section">
          <h2>Contact Us</h2>
          <p className="description">
            If you have any questions or concerns about this Privacy Policy or
            our practices, please contact us at [insert contact information
            here].
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
