import { Routes, Route, Navigate } from 'react-router-dom';
import { getUserDataLocally, isAuthenticated } from '../../api/account';
import Navbar from '../../components/Navbar/Navbar';
import Settings from './Settings';
import Portfolio from './Portfolio';
import Support from './Support';
import Pricing from './Pricing';
import { useEffect, useState } from 'react';
import { changeCurrency, getUserSettings } from '../../api/user';
import { IUserSettings } from '../../models/user';
import { IPortfolioList } from '../../models/portfolio';
import { getPortfolioList } from '../../api/portfolio';

const Dashboard = () => {
  const [theme, setTheme] = useState('light');
  const [portfolioList, setPortfolioList] = useState<IPortfolioList[]>([]);
  const [currencySign, setCurrencySign] = useState('$');
  const [selectedCurrency, setSelectedCurrency] = useState<string>(getUserDataLocally()?.currency || '');

  const handleCurrencyChange = (currency: string) => {
    changeCurrency(currency)
      .then(() => {
        setSelectedCurrency(currency);
      })
      .catch((err) => console.error(err));
    if (currency === 'USD') setCurrencySign('$');
    else if (currency === 'EUR') setCurrencySign('€');
    localStorage.setItem('currency', currency);
  };

  useEffect(() => {
    getUserSettings()
      .then((res: IUserSettings) => {
        if (res.theme === 0) setTheme('light');
        else setTheme('dark');

        localStorage.setItem('theme', theme);
      })
      .catch((error) => {
        console.error(error);
      });

    getPortfolioList().then((portfolio) => setPortfolioList(portfolio.data));

    if (selectedCurrency === 'USD') setCurrencySign('$');
    else if (selectedCurrency === 'EUR') setCurrencySign('€');
  }, []);

  useEffect(() => {
    document?.querySelector('html')?.setAttribute('data-theme', theme);
  }, [theme]);

  // Redirect to dashboard/portfolio if authenticated, else redirect to login
  return isAuthenticated() ? (
    <Navbar
      theme={theme}
      portfolioList={portfolioList || []}
      setPortfolioList={setPortfolioList}
      selectedCurrency={selectedCurrency}
      handleCurrencyChange={handleCurrencyChange}
    >
      <Routes>
        <Route path="/" element={<Navigate to="portfolio" />} />
        <Route path="settings" element={<Settings theme={theme} setTheme={setTheme} />} />
        <Route path="support" element={<Support />} />
        <Route path="pricing" element={<Pricing />} />
        <Route
          path="portfolio"
          element={
            <Portfolio
              portfolioList={portfolioList || []}
              setPortfolioList={setPortfolioList}
              currencySign={currencySign}
              selectedCurrency={selectedCurrency}
            />
          }
        />
        <Route
          path="portfolio/:id"
          element={
            <Portfolio
              portfolioList={portfolioList || []}
              setPortfolioList={setPortfolioList}
              currencySign={currencySign}
              selectedCurrency={selectedCurrency}
            />
          }
        />
      </Routes>
    </Navbar>
  ) : (
    <Navigate to="/app" />
  );
};

export default Dashboard;
