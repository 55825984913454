import { useParams } from "react-router-dom";
import work from "../../assets/icons/work.svg";
import { IPortfolioSummary } from "../../models/portfolio";
import profit from "../../assets/icons/profit.svg";
import discount from "../../assets/icons/discount.svg";
import calendar from "../../assets/icons/calendar.svg";
import up_icon from "../../assets/icons/up_icon.svg";
import down_icon from "../../assets/icons/down_icon.svg";
import { useTranslation } from "react-i18next";
import { analyzeChartDirection } from "../../utils/commons";
import positive_sign from "../../assets/icons/positive_sign.svg";
import negative_sign from "../../assets/icons/negative_sign.svg";

interface IPortfolioTiles {
  isMobile: boolean;
  isAnalytics: boolean;
  isLoading: boolean;
  currencySign: string;
  portfolioSummary: IPortfolioSummary | null;
}

const PortfolioTiles = ({
  isMobile,
  isAnalytics,
  isLoading,
  currencySign,
  portfolioSummary,
}: IPortfolioTiles) => {
  const { id } = useParams();
  const [t] = useTranslation("global");

  const yeildChartDirection = analyzeChartDirection(
    portfolioSummary?.yeildChart
  );
  const changPerDayChartDirection = analyzeChartDirection(
    portfolioSummary?.changePerDayChart
  );

  return isMobile ? (
    <div className={`flex flex-wrap justify-between pt-${id ? "0" : "6"}`}>
      <div className="flex w-[100%] gap-2">
        {/* value */}
        <div className="mb-2 flex-grow w-[50%]">
          <div className="focus:outline-none border border-gray-300 bg-base-300 p-2 rounded-[0.75rem] w-full">
            <div className="flex flex-row items-center pl-1">
              <img
                src={work}
                width={20}
                height={20}
                className="mr-1"
                alt="Icon"
              />
              <p className="text-[15px] ml-1 text-gray-400">
                {t("portfolio.value")}
              </p>
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center pt-4">
                <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
              </div>
            ) : (
              <h1 className="text-[2rem] font-bold">
                {currencySign}
                <span className="ml-1">
                  {portfolioSummary?.value?.toFixed(2) || 0}
                </span>
              </h1>
            )}
          </div>
        </div>

        {/* Yield */}
        <div className="mb-2 flex-grow w-[50%]">
          <div className="focus:outline-none border border-gray-300 bg-base-300 p-2 rounded-[0.75rem] w-full">
            <div className="flex flex-row items-center justify-between pl-1">
              <div className="flex">
                <img
                  src={discount}
                  width={20}
                  height={20}
                  className="mr-1"
                  alt="Icon"
                />
                <p className="text-[15px] ml-1 text-gray-400">
                  {t("portfolio.yield")}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={
                    yeildChartDirection === "positive"
                      ? positive_sign
                      : negative_sign
                  }
                  width={25}
                  height={25}
                  alt="Chart"
                />
              </div>
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center pt-4">
                <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
              </div>
            ) : (
              <h1 className="text-[2rem] font-bold">
                {portfolioSummary?.yield?.toFixed(2) || 0}
                <span className="ml-1">%</span>
              </h1>
            )}
          </div>
        </div>
      </div>

      <div className="flex w-[100%] gap-2">
        {/* Profit */}
        <div className="flex-grow w-[50%]">
          <div className="focus:outline-none border border-gray-300 bg-base-300 p-2 rounded-[0.75rem] w-full">
            <div className="flex flex-row items-center justify-between pl-1">
              <div className="flex">
                <img
                  src={profit}
                  width={20}
                  height={20}
                  className="mr-1"
                  alt="Icon"
                />
                <p className="text-[15px] ml-1 text-gray-400">
                  {t("portfolio.profit")}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={
                    portfolioSummary?.profit && portfolioSummary.profit >= 0
                      ? up_icon
                      : down_icon
                  }
                  width={20}
                  height={20}
                  alt="Icon"
                />
                <p
                  className={`text-[15px] ${
                    portfolioSummary?.profit && portfolioSummary.profit >= 0
                      ? "text-[#8DC63F] pl-1"
                      : "text-[#D92C3F] pl-1"
                  }`}
                >
                  {portfolioSummary?.profit?.toFixed(2) || 0}
                </p>
              </div>
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center pt-4">
                <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
              </div>
            ) : (
              <h1 className="text-[2rem] font-bold">
                {currencySign}
                <span className="ml-1">
                  {portfolioSummary?.profit?.toFixed(2) || 0}
                </span>
              </h1>
            )}
          </div>
        </div>

        {/* Change per day */}
        <div className="flex-grow w-[50%]">
          <div className="focus:outline-none border border-gray-300 bg-base-300 p-2 rounded-[0.75rem] w-full">
            <div className="flex flex-row items-center justify-between pl-1">
              <div className="flex items-center">
                <img
                  src={calendar}
                  width={20}
                  height={20}
                  className="mr-1"
                  alt="Icon"
                />
                <p className="text-[12px] ml-1 text-gray-400">
                  {t("portfolio.change")}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={
                    changPerDayChartDirection === "positive"
                      ? positive_sign
                      : negative_sign
                  }
                  width={25}
                  height={25}
                  alt="Chart"
                />
              </div>
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center pt-4">
                <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
              </div>
            ) : (
              <h1 className="text-[2rem] font-bold">
                {currencySign}
                <span className="ml-1">
                  {portfolioSummary?.changePerDay?.toFixed(2) || 0}
                </span>
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : !isAnalytics ? (
    <div
      className={`flex justify-between pb-8 pt-${
        id ? "0" : "8"
      } gap-[30px] w-[100%]`}
    >
      {/* value */}
      <div
        className="focus:outline-none border border-gray-300 bg-base-300 px-8 py-4 rounded-[0.75rem] h-30"
        style={{ flexBasis: "25%" }}
      >
        <div className="flex flex-row items-center pl-1">
          <img src={work} width={16} height={16} className="mr-2" alt="Icon" />
          <p className="text-[16px] ml-1 text-gray-400">
            {t("portfolio.value")}
          </p>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center pt-4">
            <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
          </div>
        ) : (
          <h1 className="text-[36px] mt-3 font-bold">
            {currencySign}
            <span className="ml-1">
              {portfolioSummary?.value?.toFixed(2) || 0}
            </span>
          </h1>
        )}
      </div>

      {/* Profit */}
      <div
        className="focus:outline-none border border-gray-300 bg-base-300 px-8 py-4 rounded-[0.75rem]  h-30"
        style={{ flexBasis: "25%" }}
      >
        <div className="flex flex-row items-center justify-between pl-1">
          <div className="flex">
            <img
              src={profit}
              width={16}
              height={16}
              className="mr-2"
              alt="Icon"
            />
            <p className="text-[16px] ml-1 text-gray-400">
              {t("portfolio.profit")}
            </p>
          </div>
          <div className="flex items-center">
            <img
              src={
                portfolioSummary?.profit && portfolioSummary.profit >= 0
                  ? up_icon
                  : down_icon
              }
              width={20}
              height={20}
              alt="Icon"
            />
            <p
              className={`text-[17px] ${
                portfolioSummary?.profit && portfolioSummary.profit >= 0
                  ? "text-[#8DC63F] pl-2"
                  : "text-[#D92C3F] pl-2"
              }`}
            >
              {portfolioSummary?.profit?.toFixed(2) || 0}
            </p>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center pt-4">
            <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
          </div>
        ) : (
          <h1 className="text-[36px] mt-3 font-bold">
            {currencySign}
            <span className="ml-1">
              {portfolioSummary?.profit?.toFixed(2) || 0}
            </span>
          </h1>
        )}
      </div>

      {/* Yeild*/}
      <div
        className="focus:outline-none border border-gray-300 bg-base-300 px-8 py-4 rounded-[0.75rem] h-30"
        style={{ flexBasis: "25%" }}
      >
        <div className="flex flex-row items-center justify-between pl-1">
          <div className="flex">
            <img
              src={discount}
              width={16}
              height={16}
              className="mr-2 fill-blue"
              alt="Icon"
            />
            <p className="text-[16px] ml-1 text-gray-400">
              {t("portfolio.yield")}
            </p>
          </div>
          <div className="flex items-center">
            <img
              src={
                yeildChartDirection === "positive"
                  ? positive_sign
                  : negative_sign
              }
              width={32}
              height={20}
              alt="Chart"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center pt-4">
            <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
          </div>
        ) : (
          <h1 className="text-[36px] mt-3 font-bold">
            {portfolioSummary?.yield?.toFixed(2) || 0}
            <span className="ml-1">%</span>
          </h1>
        )}
      </div>

      {/* Change per day */}
      <div
        className="focus:outline-none border border-gray-300 bg-base-300 px-8 py-4 rounded-[0.75rem] h-30"
        style={{ flexBasis: "25%" }}
      >
        <div className="flex flex-row items-center justify-between pl-1">
          <div className="flex">
            <img
              src={calendar}
              width={16}
              height={16}
              className="mr-2"
              alt="Icon"
            />
            <p className="text-[16px] ml-1 text-gray-400">
              {t("portfolio.change")}
            </p>
          </div>
          <div className="flex items-center">
            <img
              src={
                changPerDayChartDirection === "positive"
                  ? positive_sign
                  : negative_sign
              }
              width={32}
              height={20}
              alt="Chart"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center pt-4">
            <span className="loading loading-spinner px-6 py-6 loading-lg text-[#8C56E1]"></span>
          </div>
        ) : (
          <h1 className="text-[36px] mt-3 font-bold">
            {currencySign}
            <span className="ml-1">
              {portfolioSummary?.changePerDay?.toFixed(2) || 0}
            </span>
          </h1>
        )}
      </div>
    </div>
  ) : null;
};

export default PortfolioTiles;
