import { IPortfolioAssets } from "../../models/portfolio";
import { useTranslation } from "react-i18next";
import PortfolioAssetsTable from "../Tables/PortfolioAssetsTable";
import empty_table from "../../assets/icons/empty_table.png";
import { NavArrowDown, NavArrowUp } from "iconoir-react";
import up_arrow from "../../assets/icons/up_arrow.svg";
import down_arrow from "../../assets/icons/down_arrow.svg";

interface IPortfolioAssetsBlock {
  isMobile: boolean;
  isLoading: boolean;
  portfolioAssets: IPortfolioAssets[];
  handleAccordionToggle: (x: number | null) => void;
  openAccordion: number | null;
}

const PortfolioAssetsBlock = ({
  isMobile,
  isLoading,
  portfolioAssets,
  handleAccordionToggle,
  openAccordion,
}: IPortfolioAssetsBlock) => {
  const [t] = useTranslation("global");

  return (
    <div className="pb-16 pt-6">
      <h1 className="text-[16px] font-bold mb-4 pl-1">
        {t("portfolio.assets")}
      </h1>
      <div className="overflow-x-auto border border-gray-300 rounded-lg">
        {!isMobile && (
          <PortfolioAssetsTable data={portfolioAssets} isLoading={isLoading} />
        )}
        {isMobile && portfolioAssets.length === 0 && (
          <div className="flex justify-center items-center text-center h-25 rounded-b-lg">
            <img
              src={empty_table}
              height={250}
              width={250}
              alt="Icon"
              className="mx-auto my-4"
            />
          </div>
        )}
        {portfolioAssets.length > 0 &&
          portfolioAssets.map((accordion, index) => (
            <div
              className="border border-gray-300 rounded-lg lg:hidden"
              key={index}
            >
              <div
                className="flex justify-between items-center cursor-pointer p-4 bg-base-300 text-base-conent rounded-t-lg"
                onClick={() => handleAccordionToggle(index)}
              >
                <h2 className="text-lg lg:w-auto w-[80%] font-bold text-base-content">
                  {accordion.name}
                </h2>
                {openAccordion === index ? (
                  <NavArrowUp width={25} height={25} />
                ) : (
                  <NavArrowDown width={25} height={25} />
                )}
              </div>
              <div
                className={`bg-base-300 transition-max-height ease-in-out duration-1000 overflow-hidden rounded-b-lg ${
                  openAccordion === index ? "max-h-65" : "max-h-0"
                }`}
              >
                <div className="p-3 py-4 mb-2 text-[20px]">
                  <div className="flex justify-between items-center py-1 mb-1 bg-base-300">
                    <p className="text-base-content">
                      {t("portfolio.currentValue")}
                    </p>
                    <p className="font-bold">{accordion.amount}</p>
                  </div>
                  <div className="flex justify-between items-center py-1 mb-1 bg-base-300">
                    <p className="text-base-content">{t("portfolio.profit")}</p>
                    <span
                      style={{
                        backgroundColor:
                          accordion.currentProfit >= 0 ? "#F1F8E8" : "#F7D5D9",
                      }}
                      className={`flex items-center  text-[${
                        accordion.currentProfit >= 0 ? "#8DC63F" : "#D92C3F"
                      }] rounded-md px-4 py-1`}
                    >
                      <img
                        src={
                          accordion.currentProfit >= 0 ? up_arrow : down_arrow
                        }
                        width={10}
                        height={10}
                        className="mr-2"
                        alt="Icon"
                      />
                      {accordion.currentProfit ?? "-"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-1 mb-1 bg-base-300">
                    <p className="text-base-content">{t("portfolio.pl")}</p>
                    <span
                      style={{
                        backgroundColor:
                          accordion.profitLostPerDay >= 0
                            ? "#F1F8E8"
                            : "#F7D5D9",
                      }}
                      className={`flex items-center  text-[${
                        accordion.profitLostPerDay >= 0 ? "#8DC63F" : "#D92C3F"
                      }] rounded-md px-4 py-1`}
                    >
                      <img
                        src={
                          accordion.profitLostPerDay >= 0
                            ? up_arrow
                            : down_arrow
                        }
                        width={10}
                        height={10}
                        className="mr-2"
                        alt="Icon"
                      />
                      {accordion.profitLostPerDay ?? "-"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-1 mb-1 bg-base-300">
                    <p className="text-base-content">{t("portfolio.pl%")}</p>
                    <span
                      style={{
                        backgroundColor:
                          accordion.dividendsYield >= 0 ? "#F1F8E8" : "#F7D5D9",
                      }}
                      className={`flex items-center  text-[${
                        accordion.dividendsYield >= 0 ? "#8DC63F" : "#D92C3F"
                      }] rounded-md px-4 py-1`}
                    >
                      <img
                        src={
                          accordion.dividendsYield >= 0 ? up_arrow : down_arrow
                        }
                        width={10}
                        height={10}
                        className="mr-2"
                        alt="Icon"
                      />
                      {accordion.dividendsYield ?? "-"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-1 mb-1 bg-base-300">
                    <p className="text-base-content">{t("portfolio.share")}</p>
                    <p className="font-bold">{accordion.dividends}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PortfolioAssetsBlock;
