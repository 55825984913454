import check from "../../assets/dashboard/pricing/check.svg";

export interface PlanCardProps {
  backgroundImage: string;
  title: string;
  priceMonth: string;
  priceYear: string;
  isMonth: boolean;
  features: string[];
  height: string;
}

const PlanCard: React.FC<PlanCardProps> = ({
  backgroundImage,
  title,
  priceMonth,
  priceYear,
  isMonth,
  features,
  height,
}) => (
  <div
    className="h-auto bg-cover bg-white rounded-[40px] overflow-hidden p-[10%] text-black"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      minHeight: `${height}`,
    }}
  >
    <h2 className="text-[24px] font-bold">{title}</h2>
    <h1 className="mt-2 mb-5">
      <span className="text-[24px] font-bold">$</span>
      <span className="text-[40px] font-bold">{isMonth ? priceMonth : priceYear}</span>
      <span className="text-[16px]" style={{ color: "gray" }}>
        /{isMonth ? "month" : "year"}
      </span>
    </h1>
    <ul className="list-disc list-inside">
      {features.map((feature, index) => (
        <li
          key={index}
          className={`flex items-center ${index > 1 ? "opacity-50" : ""} mb-2`}
        >
          <img src={check} className="w-4 h-4 mr-2" alt="Check Icon" />
          {feature}
        </li>
      ))}
    </ul>
    <button className="btn btn-secondary px-4 py-2 w-full mt-20" disabled>
      Choose
    </button>
  </div>
);

export default PlanCard;
