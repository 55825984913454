import { AxiosResponse } from 'axios';
import axiosApi from '../utils/axios';
const ACCOUNT_ENDPOINT = '/Account';

export interface IUserData {
  name?: string;
  currency: string;
  language: string;
  image?: string | null;
  token?: string;
  refreshToken: string;
  expirationTime?: number;
}

export const storeUserDataLocally = (userData: IUserData) => {
  const { language, currency, ...restUserData } = userData;

  localStorage.setItem('language', language);
  localStorage.setItem('currency', currency);

  localStorage.setItem('userData', JSON.stringify(restUserData));
};

export const getUserDataLocally = (): IUserData | null => {
  const userDataString = localStorage.getItem('userData');
  const language = localStorage.getItem('language');
  const currency = localStorage.getItem('currency');

  if (userDataString && language && currency) {
    const userData: IUserData = {
      ...JSON.parse(userDataString),
      language,
      currency
    };
    return userData;
  }
  return null;
};

export const loginUser = async (loginData: any): Promise<IUserData | null> => {
  try {
    const response: AxiosResponse<IUserData> = await axiosApi.post(
      `${ACCOUNT_ENDPOINT}/Login`,
      loginData
    );
    const userData: IUserData = response.data;
    storeUserDataLocally(userData);
    return userData;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const signinUser = async (loginData: any): Promise<IUserData | null> => {
  try {
    const response: AxiosResponse<IUserData> = await axiosApi.post(
      `${ACCOUNT_ENDPOINT}/SignIn`,
      loginData
    );
    const userData: IUserData = response.data;
    debugger;
    storeUserDataLocally(userData);
    return userData;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const registerUser = async (
  registerData: any
): Promise<IUserData | null> => {
  try {
    const response: AxiosResponse<IUserData> = await axiosApi.post(
      `${ACCOUNT_ENDPOINT}/Registration`,
      registerData
    );
    const userData: IUserData = response.data;
    storeUserDataLocally(userData);
    return userData;
  } catch (error) {
    console.error('Registration failed:', error);
    throw error;
  }
};

export const isAuthenticated = (): boolean => {
  const userData = getUserDataLocally();
  if (userData && userData.token) {
    return true;
  }
  logoutUser();
  return false;
};

export const logoutUser = () => {
  localStorage.removeItem('userData');
  window.location.href = '/app/auth';
};

export const getCurrencies = async (): Promise<any> => {
  try {
    return await axiosApi.get(`${ACCOUNT_ENDPOINT}/currencies`);
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};

export const getLanguages = async (): Promise<any> => {
  try {
    const response = await axiosApi.get(`${ACCOUNT_ENDPOINT}/languages`);
    return response;
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};
