import { useNavigate } from "react-router-dom";
import checkWhite from "../../assets/dashboard/pricing/check_white.svg";

interface PlanCardProps {
  backgroundImage: string;
  title: string;
  priceMonth: string;
  priceYear: string;
  isMonth: boolean;
  features: string[];
  height: string;
}

const ExtendedPlanCard: React.FC<PlanCardProps> = ({
  backgroundImage,
  title,
  priceMonth,
  priceYear,
  isMonth,
  height,
  features,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="h-auto bg-cover rounded-[40px] overflow-hidden p-[10%] text-white"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        minHeight: `${height}`,
      }}
    >
      <h2 className="text-[24px] font-bold text-white">{title}</h2>
      <h1 className="mt-2 mb-5">
        <span className="text-[24px] font-bold">$</span>
        <span className="text-[40px] font-bold">{isMonth ? priceMonth : priceYear}</span>
        <span className="text-[16px]">/{isMonth ? "month" : "year"}</span>
      </h1>
      <ul className="list-disc list-inside">
        {features.map((feature, index) => (
          <li
            key={index}
            className={`flex items-center text-white ${
              index > 1 ? "opacity-50" : ""
            } mb-2`}
          >
            <img src={checkWhite} className="w-4 h-4 mr-2" alt="Check Icon" />
            {feature}
          </li>
        ))}
      </ul>
      <button
        className="btn btn-primary px-4 py-2 w-full mt-[35%]"
        onClick={() => navigate("app/auth")}
      >
        Get Started
      </button>
    </div>
  );
};

export default ExtendedPlanCard;
