import { AxiosResponse } from 'axios';
import axiosApi from '../utils/axios';

const PORTFOLIO_ENDPOINT = '/Portfolio';

// Function to get portfolio list
export const getPortfolioList = async (): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/List`);
  } catch (error) {
    console.error('Error Fetching Portfolio List:', error);
    throw error;
  }
};

// Function to get portfolio summary
export const getPortfolioSummary = async (): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Summary`);
  } catch (error) {
    console.error('Error Fetching Portfolio Summary:', error);
    throw error;
  }
};

// Function to get portfolio Summary By Id
export const getPortfolioSummaryById = async (Id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Summary/${Id}`);
  } catch (error) {
    console.error('Error Fetching Portfolio Summary By Id:', error);
    throw error;
  }
};

// Function to get portfolio Assets
export const getPortfolioAssets = async (): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Assets`);
  } catch (error) {
    console.error('Error Fetching Portfolio Assets:', error);
    throw error;
  }
};

// Function to get portfolio Assets By Id
export const getPortfolioAssetsById = async (Id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Assets/${Id}`);
  } catch (error) {
    console.error('Error Fetching Portfolio Assets By Id:', error);
    throw error;
  }
};

// Function to get portfolio Cash BY Id
export const getPortfolioCashById = async (Id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Cash/${Id}`);
  } catch (error) {
    console.error('Error Fetching Portfolio Cash By Id:', error);
    throw error;
  }
};
// Function to get portfolio Cash
export const getPortfolioCash = async (): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Cash`);
  } catch (error) {
    console.error('Error Fetching Portfolio Cash:', error);
    throw error;
  }
};

// Function to get portfolio Stocks
export const getPortfolioStocks = async (): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Stocks`);
  } catch (error) {
    console.error('Error Fetching Portfolio Stocks:', error);
    throw error;
  }
};

// Function to get portfolio Stocks By Id
export const getPortfolioStocksById = async (Id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Stocks/${Id}`);
  } catch (error) {
    console.error('Error Fetching Portfolio Stocks By Id:', error);
    throw error;
  }
};

// Function to get portfolio Deals
export const getPortfolioDeals = async (): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Deals`);
  } catch (error) {
    if (error !== undefined) {
      console.error('Error Fetching Portfolio Deals:', error);
    }
    throw error;
  }
};

// Function to get portfolio Deals
export const getPortfolioDealsById = async (Id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.get(`${PORTFOLIO_ENDPOINT}/Deals/${Id}`);
  } catch (error) {
    console.error('Error Fetching Portfolio Deals By Id:', error);
    throw error;
  }
};

// Function to create portfolio
export const createPortfolio = async (name: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.post(`${PORTFOLIO_ENDPOINT}`, { name });
  } catch (error) {
    console.error('Error creating Portfolio', error);
    throw error;
  }
};

// Function to update portfolio title
export const updatePortfolioTitle = async (id: string, name: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.post(`${PORTFOLIO_ENDPOINT}/${id}`, { name });
  } catch (error) {
    console.error('Error updating Portfolio title', error);
    throw error;
  }
};

// Function to upload file
export const uploadCsvFile = async (id: string, broker: string, formFile: File): Promise<AxiosResponse<any>> => {
  const data = new FormData();
  data.append('Broker', broker);
  data.append('FormFile', formFile);

  try {
    return await axiosApi.post(`${PORTFOLIO_ENDPOINT}/${id}/File`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error uploading file', error);
    throw error;
  }
};

// Function to delete portfolio
export const deletePortfolio = async (id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.delete(`${PORTFOLIO_ENDPOINT}/${id}`);
  } catch (error) {
    console.error('Error deleting porfolio', error);
    throw error;
  }
};

// Function to delete deal
export const deleteDeal = async (id: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.delete(`${PORTFOLIO_ENDPOINT}/deal/${id}`);
  } catch (error) {
    console.error('Error deleting deal', error);
    throw error;
  }
};

// Function to add deal
//todo This api is yet to be created
export const addDeal = async (id: string, data: any): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.post(`${PORTFOLIO_ENDPOINT}/${id}/add-deal`, {
      data,
    });
  } catch (error) {
    console.error('Error adding deal', error);
    throw error;
  }
};
