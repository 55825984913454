import '../styles/terms.scss';
import background from '../assets/terms/bg.png';
import bg_mobile from '../assets/terms/bg-mobile.png';
import logo from '../assets/terms/logo-mobile.png';
import { useEffect, useState } from 'react';
import mainLogo from '../assets/logos/logo_light.png';

const Terms = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="terms-container bg-white">
      <div className="header">
        {/* Conditional rendering based on screen size */}
        {window.innerWidth > 500 ? (
          <>
            <header className="lg:flex py-2 px-10 text-white text-center shadow-lg border border-b-gray-300">
              <div className="flex items-center justify-center">
                <img src={mainLogo} width={200} height={200} alt="logo" />
              </div>
            </header>
            <img
              src={background}
              alt="Background"
              className="max-h-[30vh] shadow-md"
            />
            <div className="img-text">
              <h1>Terms of service</h1>
              <p className="sub-heading">Have questions? We're here to help.</p>
            </div>
          </>
        ) : (
          <img src={bg_mobile} alt="Mobile Background" />
        )}

        {/* Conditional rendering of logo */}
        {window.innerWidth <= 500 && (
          <>
            <img src={logo} alt="Logo" className="logo" />
            <div className="img-text">
              <h1>Terms of service</h1>
              <p className="sub-heading">Have questions? We're here to help.</p>
            </div>
          </>
        )}
      </div>
      <div className="body">
        <p className="description">
          Thank you for visiting our website.These Terms of Service outline the
          terms and conditions that govern your use of our website and any
          products or services we provide. By accessing or using our website,
          you agree to be bound by these Terms of Service.
        </p>
        <div className="section">
          <h2>1. Use of Website</h2>
          <p className="description">
            You may use our website for lawful purposes only. You agree not to
            use our website for any illegal or unauthorized purpose, including
            but not limited to:
          </p>
          <ol>
            <li>1. Violating any laws in your jurisdiction</li>
            <li>
              2. Interfering with or disrupting the operation of our website or
              its servers
            </li>
            <li>
              3. Uploading or transmitting viruses or other malicious code
            </li>
            <li>
              4. Collecting or storing personal information of other users
              without their consent
            </li>
          </ol>
        </div>

        <div className="section">
          <h2>2. Intellectual Property</h2>
          <p className="description">
            Our website and its content, including but not limited to text,
            graphics, logos, images, and software, are protected by copyright,
            trademark, and other intellectual property laws. You may not
            reproduce, distribute, modify, or create derivative works of any
            part of our website or its content without our prior written
            consent.
          </p>
        </div>

        <div className="section">
          <h2>3. Links to Other Websites</h2>
          <p className="description">
            Our website may contain links to other websites that are not
            operated by us. We are not responsible for the content or accuracy
            of these websites and do not endorse or warrant any products or
            services offered on these websites. You use these websites at your
            own risk.
          </p>
        </div>

        <div className="section">
          <h2>4. Limitation of Liability</h2>
          <p className="description">
            We make no warranties or representations about the accuracy or
            completeness of the content on our website. We will not be liable
            for any damages, including but not limited to direct, indirect,
            incidental, punitive, or consequential damages, arising from the use
            of our website or any products or services we provide.
          </p>
        </div>

        <div className="section">
          <h2>5. Indemnification</h2>
          <p className="description">
            You agree to indemnify and hold us harmless from any claims,
            damages, expenses, or other losses arising from your use of our
            website, your violation of these Terms of Service, or your
            infringement of any intellectual property or other rights of any
            person or entity.
          </p>
        </div>

        <div className="section">
          <h2>6. Termination</h2>
          <p className="description">
            We reserve the right to terminate your access to our website or any
            products or services we provide at any time and for any reason,
            without notice or liability.
          </p>
        </div>

        <div className="section">
          <h2>7. Governing Law</h2>
          <p className="description">
            These Terms of Service shall be governed by and construed in
            accordance with the laws of [insert jurisdiction]. Any legal action
            arising out of or relating to these Terms of Service or your use of
            our website shall be brought exclusively in the courts of [insert
            jurisdiction].
          </p>
        </div>

        <div className="section">
          <h2>8. Changes to these Terms of Service</h2>
          <p className="description">
            We reserve the right to change these Terms of Service at any time.
            We will notify you of any changes by posting the updated Terms of
            Service on our website with an updated date. Your continued use of
            our website after the effective date of any changes constitutes your
            acceptance of the changes.
          </p>
        </div>

        <div className="section">
          <h2>9. Contact Us</h2>
          <p className="description">
            If you have any questions or concerns about these Terms of Service
            or our practices, please contact us at [insert contact information
            here].
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
