import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IPortfolioList } from "../../models/portfolio";
import burger from "../../assets/icons/burger.svg";
import add_deal from "../../assets/icons/add_deal.svg";
import note from "../../assets/icons/note.svg";
import deleteIcon from "../../assets/icons/delete.png";
import portfolio from "../../assets/icons/portfolio_logo.svg";
import portfolio_inactive from "../../assets/icons/portfolio_logo_gray.svg";
import analytics from "../../assets/icons/analytics_colored.svg";
import analytics_inactive from "../../assets/icons/analytics.svg";

interface IHeader {
  isMobile: boolean;
  isSidebarOpen: boolean;
  isAnalytics: boolean;
  setIsAnalytics: (x: boolean) => void;
  setIsSidebarOpen: (x: boolean) => void;
  setShowAddDealModal: (x: boolean) => void;
  setShowChangeTitleModal: (x: boolean) => void;
  setShowDeletePortfolioModal: (x: boolean) => void;
  portfolioList: IPortfolioList[];
}

const Header = ({
  isMobile,
  isSidebarOpen,
  isAnalytics,
  setIsAnalytics,
  setIsSidebarOpen,
  setShowAddDealModal,
  setShowChangeTitleModal,
  setShowDeletePortfolioModal,
  portfolioList,
}: IHeader) => {
  const [t] = useTranslation("global");
  const { id } = useParams();

  return isMobile ? (
    <div className="flex justify-between items-center mb-2">
      <div className="flex justify-center items-center">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="focus:outline-none border bg-base-300 border-gray-300 px-2 py-2 rounded-md"
        >
          <img src={burger} alt="Sidebar Toggle" className="w-6 h-6" />
        </button>

        {!id && <p className="font-bold pl-3 text-[35px]">{t("nav.link1")}</p>}
      </div>

      <div className="flex gap-2">
        {portfolioList.length > 0 && (
          <button
            className="focus:outline-none border bg-base-300 border-gray-300 px-2 py-2 rounded-md"
            onClick={() => setShowAddDealModal(true)}
          >
            <img src={add_deal} alt="Add-Deal" className="w-6 h-6" />
          </button>
        )}

        {id && (
          <>
            <button
              className="focus:outline-none border bg-base-300 border-gray-300 px-2 py-2 rounded-md"
              onClick={() => setShowChangeTitleModal(true)}
            >
              <img src={note} alt="Edit-Icon" className="w-6 h-6" />
            </button>
            <button
              className="focus:outline-none border bg-base-300 border-gray-300 px-2 py-2 rounded-md"
              onClick={() => setShowDeletePortfolioModal(true)}
            >
              <img src={deleteIcon} alt="Delete-Icon" className="w-6 h-6" />
            </button>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="portfolio-header flex justify-between items-center">
      <div className="flex">
        <NavLink
          to=""
          onClick={() => setIsAnalytics(false)}
          className={`flex items-center mr-6 text-[1.5rem] border-[#8C56E1] ${
            !isAnalytics ? "border-b-[2px] text-[#8C56E1]" : "text-[gray]"
          } pb-1 px-10 cursor-pointer`}
        >
          <img
            src={!isAnalytics ? portfolio : portfolio_inactive}
            width={30}
            height={30}
            className="mr-4"
            alt="Icon"
          />
          {t("nav.link1")}
        </NavLink>
        <NavLink
          to=""
          onClick={() => setIsAnalytics(true)}
          className={`flex items-center text-[1.5rem]  border-[#8C56E1]  ${
            isAnalytics ? "border-b-[2px] text-[#8C56E1]" : "text-[gray]"
          }  pb-1 px-10 cursor-default cursor-pointer`}
        >
          <img
            src={isAnalytics ? analytics : analytics_inactive}
            width={30}
            height={30}
            className="mr-4"
            alt="Icon"
          />
          {t("nav.analytics")}
        </NavLink>
      </div>
      {portfolioList.length > 0 && (
        <>
          {!isAnalytics && (
            <button
              className="flex items-center focus:outline-none border border-gray-300 bg-base-300 mx-2 px-6 py-2 rounded-md"
              onClick={() => setShowAddDealModal(true)}
            >
              <img
                src={add_deal}
                alt="Sidebar Toggle"
                className="w-5 h-6 mr-2"
              />
              <b>{t("portfolio.addDeal")}</b>
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Header;
