import { useLocation, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import DeleteDealModal from "../../Modals/DeleteDeal";
import DeletePortfolioModal from "../../Modals/DeletePortfolio";
import ChangeTitleModal from "../../Modals/ChangeTitleModal";
import {
  deleteDeal,
  getPortfolioAssets,
  getPortfolioAssetsById,
  getPortfolioCash,
  getPortfolioCashById,
  getPortfolioDeals,
  getPortfolioDealsById,
  getPortfolioStocks,
  getPortfolioStocksById,
  getPortfolioSummary,
  getPortfolioSummaryById,
} from "../../api/portfolio";
import {
  IPortfolioAssets,
  IPortfolioSummary,
  IPortfolioDeals,
  IPortfolioStocks,
  IPortfolioCash,
  IPortfolioList,
} from "../../models/portfolio";
import AddDealModal from "../../Modals/AddDealModal";
import { logoutUser } from "../../api/account";
import { analyzeChartDirection } from "../../utils/commons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ChangeRowsModal from "../../Modals/ChangeRowsModal";
import Analytics from "../../components/Portfolio/Analytics";
import Row from "../../components/Tables/Row";
import MobileRow from "../../components/Tables/MobileRow";
import MobileSidebar from "../../components/Navbar/MobileSidebar";
import Header from "../../components/Navbar/Header";
import PortfolioInfo from "../../components/Portfolio/PortfolioInfo";
import PortfolioTiles from "../../components/Portfolio/PortfolioTiles";
import PortfolioAssetsBlock from "../../components/Portfolio/PortfolioAssetsBlock";
import PortfolioTablesBlock from "../../components/Portfolio/PortfolioTablesBlock";

const Portfolio = ({
  portfolioList,
  setPortfolioList,
  currencySign,
  selectedCurrency,
}: PropTypes) => {
  const { id } = useParams();
  const [t] = useTranslation("global");
  const location = useLocation().pathname.split("/");
  const page = location[4];
  const [deals, setDeals] = useState(false);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [portfolioCash, setPortfolioCash] = useState<IPortfolioCash[]>();
  const [portfolioSummary, setPortfolioSummary] =
    useState<IPortfolioSummary | null>(null);
  const [portfolioAssets, setPortfolioAssets] = useState<IPortfolioAssets[]>(
    []
  );
  const [portfolioDeals, setPortfolioDeals] = useState<IPortfolioDeals[]>([]);
  const [portfolioStocks, setPortfolioStocks] = useState<IPortfolioStocks[]>(
    []
  );
  const [filteredPortfolioData, setFilteredPortfolioData] = useState<
    IPortfolioDeals[] | IPortfolioStocks[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDealModal, setShowDeleteDealModal] = useState(false);
  const [showChangeTitleModal, setShowChangeTitleModal] = useState(false);
  const [showDeletePortfolioModal, setShowDeletePortfolioModal] =
    useState(false);
  const [showAddDealModal, setShowAddDealModal] = useState(false);
  const [showChangeRowsModal, setShowChangeRowsModal] = useState(false);
  const [deleteDealIndex, setDeleteDealIndex] = useState<number>(-1);
  const [deleteDealId, setDeleteDealId] = useState<string>("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1026);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);
  const [portfolioTitle, setPortfolioTitle] = useState<string>("");
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [headers, setHeaders] = useState<string[]>([
    t("portfolio.company"),
    t("portfolio.chart"),
    t("portfolio.quantity"),
    t("portfolio.averagePrice"),
    t("portfolio.currentPrice"),
    t("portfolio.purchasePrice"),
    t("portfolio.dividends"),
    t("portfolio.dividendsYeild"),
  ]);
  const [headerOptions, setHeaderOptions] = useState(headers);
  const [showCarouselIndex, setShowCarouselIndex] = useState<number | null>(
    null
  );
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e: any, label: any) => {
    setDraggedItem(label);
  };

  const handleDrop = (e: any, label: any) => {
    if (draggedItem) {
      const draggedIndex = headers.indexOf(draggedItem);
      const droppedIndex = headers.indexOf(label);
      const newHeaders = [...headers];
      newHeaders.splice(draggedIndex, 1);
      newHeaders.splice(droppedIndex, 0, draggedItem);
      setHeaders(newHeaders);
    }
  };

  const handleCheckboxChange = (label: string) => {
    setHeaders((prevHeaders) => {
      const labelIndex = prevHeaders.indexOf(label);

      if (labelIndex !== -1) {
        // If the label is in the headers array, remove it
        return prevHeaders.filter((header) => header !== label);
      } else {
        // If the label is not in the headers array, add it at the relevant index
        const indexToAdd = Math.min(
          checkboxLabels.indexOf(label),
          prevHeaders.length
        );
        return [
          ...prevHeaders.slice(0, indexToAdd),
          label,
          ...prevHeaders.slice(indexToAdd),
        ];
      }
    });
  };

  const handleColumn = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openColumnsBottomSheet = () => {
    setShowChangeRowsModal(true);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleAccordionToggle = (index: any) => {
    setOpenAccordion((prevOpenAccordion) =>
      prevOpenAccordion === index ? null : index
    );
  };

  const closeDeleteModal = () => {
    setShowDeleteDealModal(false);
  };

  const closeChangeTitleModal = () => {
    setShowChangeTitleModal(false);
  };

  const closeDeletePortfolioModal = () => {
    setShowDeletePortfolioModal(false);
  };

  const closeAddDealModal = () => {
    setShowAddDealModal(false);
  };

  const closeChangeRowModal = () => {
    setShowChangeRowsModal(false);
  };

  const handleDeleteDeal = (deleteIndex: number) => {
    const updatedDeals = [...portfolioDeals];
    deleteDeal(deleteDealId)
      .then(() => {
        updatedDeals.splice(deleteIndex, 1);
        setPortfolioDeals(updatedDeals);
        setDeleteDealIndex(-1);
        setDeleteDealId("");
        setShowDeleteDealModal(false);
        toast.success("Deal Deleted Successfully");
        setShowCarouselIndex(null);
      })
      .catch((error) => toast.error("Error deleting deal", error));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1026);
    };

    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchDataById = async (id: string) => {
    try {
      setIsLoading(true);
      const [
        portfolioCashResponse,
        portfolioSummaryResponse,
        portfolioAssetsResponse,
        portfolioDealsResponse,
        portfolioStocksResponse,
      ] = await Promise.all([
        getPortfolioCash(),
        getPortfolioSummary(),
        getPortfolioAssets(),
        getPortfolioDeals(),
        getPortfolioStocks(),
      ]);

      // Update the state with the responses
      setPortfolioCash(portfolioCashResponse.data);
      setPortfolioSummary(portfolioSummaryResponse.data);
      setPortfolioAssets(portfolioAssetsResponse.data);
      setPortfolioDeals(portfolioDealsResponse.data);
      setPortfolioStocks(portfolioStocksResponse.data);

      if (id) {
        const [
          portfolioCashByIdResponse,
          portfolioSummaryByIdResponse,
          portfolioAssetsByIdResponse,
          portfolioDealsByIdResponse,
          portfolioStocksByIdResponse,
        ] = await Promise.all([
          getPortfolioCashById(id),
          getPortfolioSummaryById(id),
          getPortfolioAssetsById(id),
          getPortfolioDealsById(id),
          getPortfolioStocksById(id),
        ]);

        // Update the state with the responses
        setPortfolioCash(portfolioCashByIdResponse.data);
        setPortfolioSummary(portfolioSummaryByIdResponse.data);
        setPortfolioAssets(portfolioAssetsByIdResponse.data);
        setPortfolioDeals(portfolioDealsByIdResponse.data);
        setPortfolioStocks(portfolioStocksByIdResponse.data);
      }
    } catch (error) {
      // Handle errors here
      if (error !== undefined) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataById(id || "");
  }, [id, selectedCurrency]);

  const checkboxLabels = [
    t("portfolio.company"),
    t("portfolio.chart"),
    t("portfolio.quantity"),
    t("portfolio.averagePrice"),
    t("portfolio.currentPrice"),
    t("portfolio.purchasePrice"),
    t("portfolio.dividends"),
    t("portfolio.dividendsYeild"),
  ];

  const logout = () => {
    logoutUser();
  };

  const MobileHeaders: string[] = [
    t("portfolio.company"),
    t("portfolio.chart"),
    t("portfolio.more"),
  ];
  const yeildChartDirection = analyzeChartDirection(
    portfolioSummary?.yeildChart
  );
  const changPerDayChartDirection = analyzeChartDirection(
    portfolioSummary?.changePerDayChart
  );

  const onDeleteDeal = (itemId: string, index: number) => {
    setShowDeleteDealModal(true);
    setDeleteDealIndex(index);
    setDeleteDealId(itemId);
  };

  const renderRow = (
    item: IPortfolioDeals | IPortfolioStocks,
    index: number
  ) => {
    return (
      <Row
        item={item}
        index={index}
        headers={headers}
        isLoading={isLoading}
        currencySign={currencySign}
        showDeals={deals}
        deleteDeal={onDeleteDeal}
      />
    );
  };

  const renderMobileRow = (
    item: IPortfolioDeals | IPortfolioStocks,
    index: number
  ) => {
    return (
      <MobileRow
        item={item}
        index={index}
        isLoading={isLoading}
        showCarouselIndex={showCarouselIndex}
        setShowCarouselIndex={setShowCarouselIndex}
      />
    );
  };

  useEffect(() => {
    if (portfolioList && id) {
      const portfolio = portfolioList.find((portfolio) => portfolio.id === id);
      if (portfolio) setPortfolioTitle(portfolio.title);
    }
  }, [portfolioList, id]);

  useEffect(() => {
    if (search) {
      const filteredData = deals ? portfolioDeals : portfolioStocks;
      const updatedFilteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredPortfolioData(updatedFilteredData);
    } else {
      setFilteredPortfolioData(deals ? portfolioDeals : portfolioStocks);
    }
  }, [search, portfolioDeals, portfolioStocks, deals]);

  return (
    <>
      {/* Modals */}
      {showDeleteDealModal && (
        <DeleteDealModal
          isOpen={showDeleteDealModal}
          deals={portfolioDeals}
          onClose={closeDeleteModal}
          onDelete={handleDeleteDeal}
          dealIndexToDelete={deleteDealIndex}
          isMobile={isMobile}
        />
      )}
      <DeletePortfolioModal
        isOpen={showDeletePortfolioModal}
        onClose={closeDeletePortfolioModal}
        isMobile={isMobile}
        portfolioTitle={portfolioTitle}
        id={id || ""}
        setPortfolioList={setPortfolioList}
      />
      <ChangeTitleModal
        isOpen={showChangeTitleModal}
        onClose={closeChangeTitleModal}
        isMobile={isMobile}
        portfolioTitle={portfolioTitle}
        portfolioId={id || ""}
        setPortfolioTitle={setPortfolioTitle}
        setPortfolioList={setPortfolioList}
      />
      <AddDealModal
        isOpen={showAddDealModal}
        onClose={closeAddDealModal}
        isMobile={isMobile}
        portfolioList={portfolioList || []}
        id={id || ""}
        setIsLoading={setIsLoading}
        setPortfolioSummary={setPortfolioSummary || null}
        setPortfolioAssets={setPortfolioAssets}
        setPortfolioDeals={setPortfolioDeals}
        setPortfolioStocks={setPortfolioStocks}
      />

      <ChangeRowsModal
        isOpen={showChangeRowsModal}
        headerOptions={headerOptions}
        headers={headers}
        onClose={closeChangeRowModal}
        handleCheckboxChange={handleCheckboxChange}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
      />

      <MobileSidebar
        isMobile={isMobile}
        isSidebarOpen={isSidebarOpen}
        submenuOpen={submenuOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        toggleSubmenu={toggleSubmenu}
        portfolioList={portfolioList}
      />

      <div className="lg:p-10 p-6">
        <Header
          isMobile={isMobile}
          isSidebarOpen={isSidebarOpen}
          isAnalytics={isAnalytics}
          setIsAnalytics={setIsAnalytics}
          setIsSidebarOpen={setIsSidebarOpen}
          setShowAddDealModal={setShowAddDealModal}
          setShowChangeTitleModal={setShowChangeTitleModal}
          setShowDeletePortfolioModal={setShowDeletePortfolioModal}
          portfolioList={portfolioList}
        />

        {/* Main section */}
        <PortfolioInfo
          isMobile={isMobile}
          isAnalytics={isAnalytics}
          portfolioTitle={portfolioTitle}
          setShowChangeTitleModal={setShowChangeTitleModal}
          setShowDeletePortfolioModal={setShowDeletePortfolioModal}
        />

        <PortfolioTiles
          isMobile={isMobile}
          isAnalytics={isAnalytics}
          isLoading={isLoading}
          currencySign={currencySign}
          portfolioSummary={portfolioSummary}
        />

        {!isAnalytics && (
          <>
            <PortfolioAssetsBlock
              isMobile={isMobile}
              isLoading={isLoading}
              portfolioAssets={portfolioAssets}
              handleAccordionToggle={handleAccordionToggle}
              openAccordion={openAccordion}
            />

            <PortfolioTablesBlock
              isMobile={isMobile}
              isLoading={isLoading}
              setDeals={setDeals}
              deals={deals}
              setSearch={setSearch}
              openColumnsBottomSheet={openColumnsBottomSheet}
              dropdownRef={dropdownRef}
              handleColumn={handleColumn}
              isDropdownOpen={isDropdownOpen}
              headerOptions={headerOptions}
              headers={headers}
              handleCheckboxChange={handleCheckboxChange}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              filteredPortfolioData={filteredPortfolioData}
              currencySign={currencySign}
              onDeleteDeal={onDeleteDeal}
              showCarouselIndex={showCarouselIndex}
              setShowCarouselIndex={setShowCarouselIndex}
              setShowDeleteDealModal={setShowDeleteDealModal}
              setDeleteDealIndex={setDeleteDealIndex}
              setDeleteDealId={setDeleteDealId}
            />
          </>
        )}
        <Analytics show={isAnalytics} />
      </div>
    </>
  );
};

interface PropTypes {
  portfolioList: IPortfolioList[];
  setPortfolioList: Dispatch<SetStateAction<IPortfolioList[]>>;
  currencySign: string;
  selectedCurrency: string;
}

export default Portfolio;
