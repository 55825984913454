import { NavLink } from "react-router-dom";

import mainlogo_white from "../../assets/main/mainlogo_white.png";
import instagram from "../../assets/main/instagram.png";
import facebook from "../../assets/main/facebook.png";
import arrow_up from "../../assets/main/arrow-up.png";
import arrow_right from "../../assets/main/arrow-right.png";
import back_top from "../../assets/main/back to top.png";

interface IFooter {
  scrollToPricing: () => void;
}

const Footer: React.FC<IFooter> = ({ scrollToPricing }) => {
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer-content">
      <div className="flex justify-center my-10 lg:my-0">
        <img className="image" src={mainlogo_white} alt="logo" width={200} />
      </div>
      <div className="headings">
        <div className="join-community">
          <span>- Join the Community</span>
          <div className="social-links">
            <a href="https://www.facebook.com/finberry_io">
              <div className="flex items-center">
                <img src={facebook} alt="Facebook" className="facebook" />
                Facebook
              </div>
              <img src={arrow_up} alt="arrow" className="arrow" />
              @finberry_io
            </a>
            <a href="https://www.instagram.com/finberry_io">
              <div className="flex items-center">
                <img src={instagram} alt="Instagram" className="instagram" />
                Instagram
              </div>
              <img src={arrow_up} alt="arrow" className="arrow" />
              @finberry_io
            </a>
          </div>
        </div>
        <div className="more-details">
          <span>- More Details</span>
          <div className="details-links">
            <div className="row">
              <a onClick={scrollToPricing} style={{ cursor: "pointer" }}>
                Pricing
                <img src={arrow_right} alt="arrow" className="arrow-right" />
              </a>
              <NavLink to={"/policy"}>
                Privacy Policy
                <img src={arrow_right} alt="arrow" className="arrow-right" />
              </NavLink>
            </div>
            <div className="row">
              <NavLink to={"app/auth"}>
                Support
                <img src={arrow_right} alt="arrow" className="arrow-right" />
              </NavLink>
              <NavLink to={"/terms"}>
                Term of Use
                <img src={arrow_right} alt="arrow" className="arrow-right" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="back-top" onClick={handleBackToTop}>
        <img src={back_top} alt="back_top" className="back-top-button" />
      </div>
      <div className="copyright">
        <p>Copyright © Finberry.io, {new Date().getFullYear()}</p>
      </div>
    </div>
  );
};

export default Footer;
