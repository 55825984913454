import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import save from '../assets/icons/save.svg';
import { toast } from 'react-toastify';
import { getPortfolioList, updatePortfolioTitle } from '../api/portfolio';
import { IPortfolioList } from '../models/portfolio';

interface ChangeTitleModalProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean;
  portfolioTitle: string;
  portfolioId: string;
  setPortfolioTitle: Dispatch<SetStateAction<string>>;
  setPortfolioList: Dispatch<SetStateAction<IPortfolioList[]>>;
}

const ChangeTitleModal = ({
  isOpen,
  onClose,
  isMobile,
  portfolioTitle,
  portfolioId,
  setPortfolioTitle,
  setPortfolioList,
}: ChangeTitleModalProps) => {
  const modalId = 'change_title_modal';
  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  const handleSaveTitle = () => {
    if (!newTitle) return toast.warn('Please enter new title');
    updatePortfolioTitle(portfolioId, newTitle)
      .then(() => {
        getPortfolioList().then((portfolio) => {
          setPortfolioTitle(newTitle);
          setPortfolioList(portfolio.data);
          toast.success('Portfolio title updated successfully');
        });
      })
      .catch(() => toast.error('Error updating portfolio'))
      .finally(() => setNewTitle(''));
    onClose();
  };

  return (
    <dialog id={modalId} className={`modal ${isMobile ? 'modal-bottom' : ''}`}>
      <div className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h3 className="font-bold text-xl text-center">Change title</h3>
        <div className="py-6 text-left">
          <label htmlFor="newTitle" className="block text-sm font-medium text-gray-700">
            New Title
          </label>
          <input
            type="text"
            id="newTitle"
            placeholder="Enter New Title"
            className="focus:border-purple-600 mt-1 p-2 border rounded-md w-full border border-gray-300"
            defaultValue={portfolioTitle}
            maxLength={60}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </div>
        <div className={`flex justify-center ${isMobile ? 'flex-col' : ''} gap-2`}>
          <button className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6  bg-[#8C56E1] text-[#ffffff]">
            <span className="flex flex-row item-center justify-center pl-3 pr-3" onClick={handleSaveTitle}>
              <img src={save} alt="portfolio-logo" className="w-6 h-6 mr-3" />
              Save
            </span>
          </button>
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6 text-[#000000]"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
    </dialog>
  );
};

export default ChangeTitleModal;
