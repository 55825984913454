import { useEffect } from 'react';
import move from '../assets/icons/move.svg';
import save from '../assets/icons/save.svg';

interface ChangeRowsModalProps {
  isOpen: boolean;
  headerOptions: string[];
  headers: string[];
  onClose: () => void;
  handleCheckboxChange: (label: string) => void;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>, label: string) => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>, label: string) => void;
}

const ChangeRowsModal = ({
  isOpen,
  headerOptions,
  headers,
  onClose,
  handleCheckboxChange,
  handleDragStart,
  handleDrop,
}: ChangeRowsModalProps) => {
  const modalId = 'change_rows_modal';
  const excludedHeaders = ['Company', 'Chart'];

  // Filter out excluded headers
  const filteredHeaderOptions = headerOptions.filter((header) => !excludedHeaders.includes(header));

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  return (
    <>
      <dialog id={modalId} className="modal modal-bottom">
        <div className="modal-box">
          <button className="btn btn-md btn-circle btn-ghost absolute right-2 top-[1.2rem]" onClick={onClose}>
            ✕
          </button>
          <h1 className="font-bold text-[1.5rem] text-center">Change Rows</h1>
          <br />
          {filteredHeaderOptions.map((label, index) => (
            <div className="flex items-center justify-between px-1 py-1" key={index}>
              <div className="form-control">
                <label className="cursor-pointer label">
                  <input
                    type="checkbox"
                    checked={headers.includes(label)}
                    className="checkbox checkbox-sm checkbox-secondary"
                    onChange={() => handleCheckboxChange(label)}
                  />
                  <span className="label-text ml-3">{label}</span>
                </label>
              </div>
              {/* Drag Icon */}
              {/* <div
                className="cursor-pointer"
                draggable="true"
                onDragStart={(e) => handleDragStart(e, label)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, label)}
              >
                <img src={move} alt="drag" />
              </div> */}
            </div>
          ))}
          <div className={`flex justify-center flex-col mt-4 gap-2`}>
            <button
              className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[7px] lg:w-32 h-15 bg-[#8C56E1] text-[#ffffff] "
              onClick={onClose}
            >
              <span className="flex flex-row justify-center items-center pr-2">
                <img src={save} alt="portfolio-logo" className="w-6 h-6 pt-[4px] pb-[4px]" />
                Save
              </span>
            </button>
            <button
              className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6 bg-base-300 text-base-content"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
      </dialog>
    </>
  );
};

export default ChangeRowsModal;
