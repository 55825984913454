import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IPortfolioList } from '../models/portfolio';
import { deletePortfolio, getPortfolioList } from '../api/portfolio';
import { Dispatch, SetStateAction, useEffect } from 'react';

interface DeletePortfolioModalProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean;
  id: string;
  portfolioTitle: string;
  setPortfolioList: Dispatch<SetStateAction<IPortfolioList[]>>;
}

const DeletePortfolioModal = ({
  isOpen,
  onClose,
  isMobile,
  id,
  portfolioTitle,
  setPortfolioList,
}: DeletePortfolioModalProps) => {
  const navigate = useNavigate();
  const modalId = 'delete_deal_modal';

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  const handleDeleteDeal = () => {
    if (!id) return;

    deletePortfolio(id)
      .then(() => {
        getPortfolioList().then((response) => setPortfolioList(response.data));
        navigate('/app/dashboard/portfolio');
        toast.success('Portfolio Deleted Successfully');
      })
      .catch(() => toast.error('Error Deleting Portfolio'))
      .finally(() => onClose());
  };

  return (
    <dialog id={modalId} className={`modal ${isMobile ? 'modal-bottom' : ''}`}>
      <div className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h3 className="font-bold text-lg text-center">Delete portfolio</h3>
        <p className="py-6 text-center text-[#8395A7]">
          Are you sure you want to delete <span className="text-[#8C56E1]">{portfolioTitle}</span>?
        </p>
        <div className="flex justify-center gap-2">
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] w-32 h-15 lg:ml-6  bg-[#8C56E1] text-[#ffffff]"
            onClick={onClose}
          >
            No
          </button>
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] w-32 h-15 lg:ml-6 text-[#000000]"
            onClick={handleDeleteDeal}
          >
            Yes
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
    </dialog>
  );
};

export default DeletePortfolioModal;
