import { NavLink, useLocation } from "react-router-dom";
import { getUserDataLocally, logoutUser } from "../../api/account";
import close_button from "../../assets/icons/close_button.png";
import portfolios from "../../assets/icons/portfolios.png";
import { useTranslation } from "react-i18next";
import { IPortfolioList } from "../../models/portfolio";
import { NavArrowDown, NavArrowLeft } from "iconoir-react";
import logoutIcon from "../../assets/icons/logout.svg";
import { Transition } from "@headlessui/react";

interface IMobileSidebar {
  isMobile: boolean;
  isSidebarOpen: boolean;
  submenuOpen: boolean;
  setIsSidebarOpen: (x: boolean) => void;
  toggleSubmenu: () => void;
  portfolioList: IPortfolioList[];
}

const MobileSidebar = ({
  isMobile,
  isSidebarOpen,
  submenuOpen,
  setIsSidebarOpen,
  toggleSubmenu,
  portfolioList,
}: IMobileSidebar) => {
  const [t] = useTranslation("global");
  const location = useLocation().pathname.split("/");

  return isMobile ? (
    <Transition
      show={isSidebarOpen}
      enter="transition-transform duration-300 ease-out"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition-transform duration-300 ease-in"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
      className="w-[19rem] p-4 z-20 bg-base-300 fixed inset-y-0 left-0 transform border-r border-gray-300"
    >
      {" "}
      {isSidebarOpen ? (
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-column justify-between border-dashed a border-b gap-[65px]">
            <p className="pl-4 pr-4 text-[20px] font-bold  px-2 py-2">
              Hello {getUserDataLocally()?.name}!
            </p>
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="focus:outline-none py-2 cursor-pointer"
            >
              <img src={close_button} alt="Sidebar Toggle" className="w-10" />
            </button>
          </div>
          <>
            <div className="mt-[3rem] flex flex-col w-[13rem]">
              <>
                <NavLink
                  to="/app/dashboard/portfolio"
                  onClick={toggleSubmenu}
                  className="flex items-center mb-3 p-2 pl-4 rounded-md text-base-content hover:bg-gray-300"
                >
                  <div className="flex gap-[5rem]">
                    <span className="flex">
                      <img
                        src={portfolios}
                        width={20}
                        height={20}
                        className="mr-2"
                        alt="Icon"
                      />
                      {t("nav.link1")}
                    </span>
                    {portfolioList.length > 0 && (
                      <span>
                        {submenuOpen ? <NavArrowDown /> : <NavArrowLeft />}
                      </span>
                    )}
                  </div>
                </NavLink>
                <div
                  style={{
                    height:
                      submenuOpen && portfolioList.length <= 10
                        ? `${portfolioList && portfolioList.length * 25}px`
                        : !submenuOpen
                        ? "0px"
                        : "15.5rem",
                  }}
                  className={`flex flex-col ml-[25px] border-l overflow-hidden ${
                    portfolioList.length >= 10 ? "overflow-y-auto" : ""
                  } transition-height duration-500`}
                >
                  {/* Render submenu items dynamically from portfolioList */}
                  {portfolioList?.map((item, index) => (
                    <NavLink
                      key={index}
                      onClick={() => setIsSidebarOpen(false)}
                      to={`/app/dashboard/portfolio/${item.id.toLowerCase()}`}
                      className={`mb-1 hover:bg-accent rounded-md transition-all duration-500 flex items-center ${
                        location[4] === item.id.toLowerCase() ? "bg-accent" : ""
                      }`}
                    >
                      <span className="text-info">&#9472;</span>
                      <span className="truncate">{item.title}</span>
                    </NavLink>
                  ))}
                </div>

                <NavLink
                  style={{
                    marginTop:
                      submenuOpen && portfolioList.length ? "10px" : "",
                  }}
                  onClick={logoutUser}
                  to="/"
                  className="flex items-center mb-3 p-2 pl-5 rounded-md text-[base-content]-700 hover:bg-accent"
                >
                  <img
                    src={logoutIcon}
                    width={20}
                    height={20}
                    className="mr-2"
                    alt="Icon"
                  />
                  {t("nav.logout")}
                </NavLink>
              </>
            </div>
          </>
        </div>
      ) : null}
    </Transition>
  ) : null;
};

export default MobileSidebar;
