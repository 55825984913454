import { useState } from 'react';
import plan1 from '../../assets/dashboard/pricing/plan01.svg';
import plan2 from '../../assets/dashboard/pricing/plan02.png';
import plan3 from '../../assets/dashboard/pricing/plan03.svg';
import { useTranslation } from 'react-i18next';
import PlanCard from '../../components/Main/PlanCard';
import ExtendedPlanCard from '../../components/Main/ExtendedPlanCard';

const Pricing = () => {
  const [toggle, setToggle] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [t, i18n] = useTranslation('global');
  const handleToggle = () => {
    setToggle(toggle === 0 ? 1 : 0);
  };

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
  };

  return (
    <>
      <div className="pl-5 pt-5 pb-20 lg:pl-10 lg:pt-10 lg:pb-0">
        <h3 className="text-[32px] font-bold">{t('pricing.header')}</h3>
        <p className="text-[16px] mt-3 text-base-content">
          {t('pricing.subheading')}
          <br />
          {t('pricing.subheading2')} <b>Visa</b> {t('pricing.and')}
          <b>Mastercard</b>.
        </p>

        <div className="form-control w-[20rem] mt-10">
          <label className="cursor-pointer label">
            <span
              className={`label-text text-[16px]  ${
                toggle === 0 ? 'font-bold' : ''
              }`}
            >
              {t('pricing.monthly')}
            </span>
            <input
              type="checkbox"
              className="toggle toggle-secondary"
              checked={toggle === 1}
              onChange={handleToggle}
            />
            <span
              className={`label-text text-[16px] flex items-center ${
                toggle === 1 ? 'font-bold' : ''
              }`}
            >
              {t('pricing.annualy')}
              <div className="rounded-lg ml-2">
                <p className="text-purple-500">
                  <span className="rounded-lg px-3  border border-purple-500">
                    -50%
                  </span>
                </p>
              </div>
            </span>
          </label>
        </div>

        <div className="my-20 w-full justify-center items-start hidden lg:flex gap-[40px]">
          <div className="w-1/4 mt-10">
            <PlanCard
              backgroundImage={plan3}
              title="Basic"
              priceMonth="10"
              priceYear="80"
              isMonth={toggle ===0}
              height="500px"
              features={[
                'Potential for high returns',
                'Diversification',
                'Ownership in companies',
                'Liquidity',
                'Protection against inflation',
              ]}
            />
          </div>

          <div className="w-1/4">
            <ExtendedPlanCard
              backgroundImage={plan2}
              title="Extended"
              priceMonth="15"
              priceYear="130"
              isMonth={toggle ===0}
              height="500px"
              features={[
                'Potential for high returns',
                'Diversification',
                'Ownership in companies',
                'Liquidity',
                'Protection against inflation',
              ]}
            />
          </div>

          <div className="w-1/4 mt-10">
            <PlanCard
              backgroundImage={plan3}
              title="Standard"
              priceMonth="12"
              priceYear="120"
              isMonth={toggle ===0}
              height="500px"
              features={[
                'Potential for high returns',
                'Diversification',
                'Ownership in companies',
                'Liquidity',
                'Protection against inflation',
              ]}
            />
          </div>
        </div>

        <div className="lg:hidden">
          <div className="carousel w-full mt-5">
            <div
              id="item1"
              className="carousel-item relative flex justify-center w-[100%]"
            >
              <PlanCard
                backgroundImage={plan1}
                title="Basic"
                priceMonth="10"
                priceYear="80"
                isMonth={toggle ===0}
                height="160px"
                features={[
                  'Potential for high returns',
                  'Diversification',
                  'Ownership in companies',
                  'Liquidity',
                  'Protection against inflation',
                ]}
              />
            </div>
            <div
              id="item2"
              className="carousel-item relative flex justify-center w-[100%]"
            >
              <ExtendedPlanCard
                backgroundImage={plan2}
                title="Extended"
                priceMonth="15"
                priceYear="130"
                isMonth={toggle ===0}
                height="220px"
                features={[
                  'Potential for high returns',
                  'Diversification',
                  'Ownership in companies',
                  'Liquidity',
                  'Protection against inflation',
                ]}
              />
            </div>
            <div
              id="item3"
              className="carousel-item relative flex justify-center w-[100%]"
            >
              <PlanCard
                backgroundImage={plan3}
                title="Standart"
                priceMonth="12"
                priceYear="120"
                isMonth={toggle ===0}
                height="160px"
                features={[
                  'Potential for high returns',
                  'Diversification',
                  'Ownership in companies',
                  'Liquidity',
                  'Protection against inflation',
                ]}
              />
            </div>
          </div>
          <div className="flex justify-center w-full py-2 gap-2">
            <a
              href="#item1"
              onClick={() => handleItemClick(1)}
              className={`flex items-center justify-center rounded-full ${
                selectedItem === 1
                  ? 'bg-primary w-5 h-5'
                  : 'bg-primary opacity-50 w-4 h-4'
              }`}
            ></a>
            <a
              href="#item2"
              onClick={() => handleItemClick(2)}
              className={`flex items-center justify-center rounded-full ${
                selectedItem === 2
                  ? 'bg-primary w-5 h-5'
                  : 'bg-primary opacity-50 w-4 h-4'
              }`}
            ></a>
            <a
              href="#item3"
              onClick={() => handleItemClick(3)}
              className={`flex items-center justify-center rounded-full ${
                selectedItem === 3
                  ? 'bg-primary w-5 h-5'
                  : 'bg-primary opacity-50 w-4 h-4'
              }`}
            ></a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
