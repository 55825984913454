import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Auth from '../pages/Auth';
import Dashboard from '../pages/Dashboard/Dashboard';
import Main from '../pages/Main';
import Terms from '../pages/Terms';
import Policy from '../pages/Policy';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/app/auth" element={<Auth />} />
        <Route path="/app/dashboard/*" element={<Dashboard />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
