import axios, { AxiosError } from 'axios';
import { logoutUser } from '../api/account';
import ENV from '../environment';

const axiosApi = axios.create({
  baseURL: ENV.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getAccessToken = () => {
  const userDataString = localStorage.getItem('userData');
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    return userData.token;
  }
  return null;
};

const getRefreshToken = () => {
  const userDataString = localStorage.getItem('userData');
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    return userData.refreshToken;
  }
  return null;
};

export const handleTokenExpiration = async () => {
  try {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      // Call your refresh token API here
      const response = await axiosApi.post('user/refresh', { refreshToken });
      const { accessToken, refreshToken: newRefreshToken } = response.data;

      // Update the access token and refresh token in local storage
      const userDataString = localStorage.getItem('userData');
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        userData.token = accessToken;
        userData.refreshToken = newRefreshToken;
        localStorage.setItem('userData', JSON.stringify(userData));
      }

      // Retry the original request
      const originalRequest = await axiosApi.request(response.config);
      return originalRequest;
    } else {
      // Handle missing refresh token
      console.error('Refresh token is missing.');
    }
  } catch (error) {
    logoutUser();
  }
};

// Interceptor to add Authorization header before each request
axiosApi.interceptors.request.use(
  async (config) => {
    // Add Authorization header if access token is available
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle response errors
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle specific error codes here, if needed
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (
        axiosError.code === 'ERR_NETWORK' ||
        axiosError.response?.status === 401
      ) {
        logoutUser(); // Logout the user
        // You can navigate the user to the login page or another appropriate location here
        return Promise.reject();
      }
    }
    // For other errors, throw the error to be handled elsewhere
    return Promise.reject(error);
  }
);

export default axiosApi;
