import { useState } from 'react';
import banner from '../../assets/dashboard/bg.png';
import { MinusCircle, PlusCircle } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
const Support = () => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [t, i18n] = useTranslation('global');

  const handleAccordionToggle = (index: any) => {
    setOpenAccordion((prevOpenAccordion) =>
      prevOpenAccordion === index ? null : index
    );
  };

  const accordions = [
    {
      title: t('support.accordian1.title'),
      content: t('support.accordian1.content'),
    },
    {
      title: t('support.accordian2.title'),
      content: t('support.accordian2.content'),
    },
    {
      title: t('support.accordian3.title'),
      content: t('support.accordian3.content'),
    },
    {
      title: t('support.accordian4.title'),
      content: t('support.accordian4.content'),
    },
    {
      title: t('support.accordian5.title'),
      content: t('support.accordian5.content'),
    },
  ];

  return (
    <>
      <div className="p-0">
        <div className="relative shadow-md">
          <img
            src={banner}
            alt="Settings Background"
            className="w-full h-[20dvh] object-cover"
          />
          <div className="absolute bottom-8 left-8 p-4 text-neutral">
            <h3 className="text-[32px] font-bold">{t('support.header')}</h3>
            <p className="text-[16px] mt-3 text-gray-500">
              {t('support.subheading')}
            </p>
          </div>
        </div>

        <div className="my-20 w-full flex justify-center">
          <div className="lg:w-[60vw] w-[90vw]">
            {accordions.map((accordion, index) => (
              <div
                className="border border-base-300 rounded-lg mb-4"
                key={index}
              >
                <div
                  className="flex justify-between items-center cursor-pointer p-4 bg-base-200 rounded-t-lg py-8"
                  onClick={() => handleAccordionToggle(index)}
                >
                  <h2 className="text-lg lg:w-auto w-[80%] font-bold text-base-content">
                    {accordion.title}
                  </h2>
                  {openAccordion === index ? (
                    <MinusCircle width={30} height={30} />
                  ) : (
                    <PlusCircle width={30} height={30} />
                  )}
                </div>
                <div
                  className={`transition-max-height ease-out duration-500 overflow-hidden ${
                    openAccordion === index ? 'max-h-48' : 'max-h-0'
                  }`}
                >
                  <div className="p-4 bg-base-200 rounded-b-lg">
                    <p>{accordion.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
