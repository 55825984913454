import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import mainLogo from "../../assets/logos/logo_light.png";
import mainBanner from "../../assets/main/hero.png";
import mainBannerMobile from "../../assets/main/hero_mobile.png";
import toogleOpen from "../../assets/main/toogle-open.png";
import toogleClose from "../../assets/main/toogle-close.png";
import logomark from "../../assets/main/logomark.png";

export const useBreakPoint = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [windowWidth];
};

const MainBanner: React.FC = () => {
  const [windowWidth] = useBreakPoint();

  return (
    <>
      <div>
        {/* Render appropriate header based on window width */}
        {windowWidth < 768 ? <SmallScreenHeader /> : <LargeScreenHeader />}
      </div>

      <div className="relative mt-5">
        <a href="/app/auth">
          <img
            src={windowWidth >= 500 ? mainBanner : mainBannerMobile}
            alt="banner"
            className="w-full object-cover mt-[-4.5rem]"
            style={{ zIndex: -1 }}
          />
        </a>
      </div>
    </>
  );
};

export default MainBanner;

// Small screen header component
const SmallScreenHeader = () => {
  const navigate = useNavigate();
  const [isToogleOpen, setIsToogleOpen] = useState(false);

  // Toggle toogle button state
  const toggleToogle = () => {
    setIsToogleOpen(!isToogleOpen);
  };

  return (
    <header className="small-screen-header ">
      <div className="flex items-center justify-between ">
        <img src={logomark} width={50} height={50} alt="logo" />

        <img
          src={isToogleOpen ? toogleClose : toogleOpen}
          alt="toggle"
          onClick={toggleToogle}
          className="cursor-pointer toggle-button"
        />
      </div>

      {/* Dropdown and buttons */}
      {isToogleOpen && (
        <div className="flex items-center  mt-4">
          {/* Login button */}
          <button
            className="login btn btn-sm btn-secondary"
            onClick={() => navigate("/app/auth?login=true")}
          >
            Login
          </button>

          {/* Register button */}
          <button
            className="register btn btn-sm btn-primary"
            onClick={() => navigate("/app/auth")}
          >
            Register
          </button>
        </div>
      )}
    </header>
  );
};

// Large screen header component
const LargeScreenHeader = () => {
  const navigate = useNavigate();

  return (
    <header className="sticky top-0 z-50 backdrop-blur bg-opacity-40 bg-white bg-clip-padding px-[12%] py-1 flex justify-between items-center">
      {/* Logo placeholder */}
      <div className="flex items-center justify-center">
        <img src={mainLogo} width={200} height={200} alt="logo" />
      </div>

      {/* Dropdown and buttons  */}
      <div className="flex items-center space-x-4">
        {/* Login button */}
        <button
          className=" login btn btn-sm btn-secondary"
          onClick={() => navigate("/app/auth?login=true")}
        >
          Login
        </button>

        {/* Register button */}
        <button
          className=" register btn btn-sm btn-primary"
          onClick={() => navigate("/app/auth")}
        >
          Register
        </button>
      </div>
    </header>
  );
};
