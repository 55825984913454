import { useParams } from "react-router-dom";
import note from "../../assets/icons/note.svg";
import deleteIcon from "../../assets/icons/delete.png";

interface IPortfolioInfo {
  isMobile: boolean;
  isAnalytics: boolean;
  portfolioTitle: string;
  setShowChangeTitleModal: (x: boolean) => void;
  setShowDeletePortfolioModal: (x: boolean) => void;
}

const PortfolioInfo = ({
  isMobile,
  isAnalytics,
  portfolioTitle,
  setShowChangeTitleModal,
  setShowDeletePortfolioModal,
}: IPortfolioInfo) => {
  const { id } = useParams();

  return id ? (
    isMobile ? (
      <div className="flex justify-between items-center pt-4 pb-2">
        <div>
          <h1 className="text-[1.5rem] font-bold pl-1 break-words">
            {portfolioTitle}
          </h1>
        </div>
      </div>
    ) : !isAnalytics ? (
      <div className="flex justify-between items-center pt-8 pb-4">
        <div>
          <h1 className="text-[1.5rem] font-bold pl-1">{portfolioTitle}</h1>
        </div>
        <div className="flex justify-center items-center gap-4 pr-2">
          <button
            className="bg-base-300 py-3 px-3 rounded-md border border-gray-300"
            title="Edit Portfolio"
            onClick={() => setShowChangeTitleModal(true)}
          >
            <img src={note} alt="note" width={22} height={22} />
          </button>
          <button
            className="bg-base-300 py-3 px-3 rounded-md border border-gray-300"
            onClick={() => setShowDeletePortfolioModal(true)}
            title="Delete Portfolio"
          >
            <img src={deleteIcon} alt="note" width={20} height={20} />
          </button>
        </div>
      </div>
    ) : null
  ) : null;
};

export default PortfolioInfo;
