import React, { useEffect, useState } from 'react';
import save from '../assets/icons/save.png';
import { Key } from 'iconoir-react';
import { updatePassword } from '../api/user';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface ChangePassModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePassModal = ({ isOpen, onClose }: ChangePassModalProps) => {
  const modalId = 'change_title_modal';
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1026);
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1026);
    };

    window.scrollTo(0, 0);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  const handleSaveTitle = () => {
    if (newPassword !== repeatNewPassword) {
      toast.info('New Passwords do not match.');
      return;
    }

    const passData = {
      password: newPassword,
      oldPassword: currentPassword,
    };
    updatePassword(passData)
      .then(() => toast.success('Password Updated'))
      .catch((error) => {
        toast.error('Request Failed. Please try again later :(');
        console.error(error);
      });

    onClose();
  };

  return (
    <dialog id={modalId} className={`modal ${isMobile ? 'modal-bottom' : ''}`}>
      <div className={`modal-box ${isMobile ? '' : 'w-5/12 max-w-4xl'}`}>
        <button
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          onClick={onClose}
        >
          ✕
        </button>
        <h3 className="font-bold text-xl text-center">
          {t('settings.changePass')}
        </h3>
        <div className="pt-6 text-left">
          <label className="block text-sm font-medium text-base-content">
            {t('settings.currentPass')}
          </label>

          <label className="input input-bordered flex items-center gap-2 focus:border-none focus:outline-none outline-none bg-base-200">
            <Key className="w-5 h-5 opacity-70" />
            <input
              type="password"
              placeholder="Enter Current Password"
              className="focus:outline-none mt-1 p-2 bg-base-200 focus:border-none border-none active:border-none active:outline-none rounded-md w-full focus:ring-0"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </label>
        </div>

        <div className="border-b border-dashed border-gray-300 border-3 w-full my-10 shadow-md"></div>

        <div
          className={`flex justify-between ${isMobile ? 'flex-col gap-6' : ''}`}
        >
          <div className="text-left lg:w-[46%] w-100">
            <label className="block text-sm font-medium text-base-content">
              {t('settings.password')}
            </label>

            <label className="input input-bordered flex items-center gap-2 focus:border-none focus:outline-none outline-none bg-base-200">
              <Key className="w-5 h-5 opacity-70" />
              <input
                type="password"
                placeholder={t("settings.newPassPlaceholder")}
                className="focus:outline-none mt-1 p-2 bg-base-200 focus:border-none border-none active:border-none active:outline-none rounded-md w-full focus:ring-0"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </label>
          </div>

          <div className="text-left lg:w-[46%] w-100">
            <label className="block text-sm font-medium text-base-content">
              {t('settings.repeatPass')}
            </label>

            <label className="input input-bordered flex items-center gap-2 focus:border-none focus:outline-none outline-none bg-base-200">
              <Key className="w-5 h-5 opacity-70" />
              <input
                type="password"
                placeholder={t("settings.repeatPlaceholder")}
                className="focus:outline-none mt-1 p-2 bg-base-200 focus:border-none border-none active:border-none active:outline-none rounded-md w-full focus:ring-0"
                value={repeatNewPassword}
                onChange={(e) => setRepeatNewPassword(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div
          className={`flex justify-center ${
            isMobile ? 'pt-10 flex-col' : 'pt-20'
          } gap-2`}
        >
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6  bg-primary text-base-content w-100"
            onClick={handleSaveTitle}
          >
            {t('button.apply')}
          </button>
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6 bg-base-300 text-base-content w-100"
            onClick={onClose}
          >
            {t('button.cancel')}
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
    </dialog>
  );
};

export default ChangePassModal;
