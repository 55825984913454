import { IPortfolioDeals, IPortfolioStocks } from "../../models/portfolio";
import { useTranslation } from "react-i18next";
import SearchBar from "../Tables/SearchBar";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import columns from "../../assets/icons/columns.png";
import move from "../../assets/icons/move.svg";
import GenericTable from "../Tables/GenericTable";
import Row from "../Tables/Row";
import MobileRow from "../Tables/MobileRow";

interface IPortfolioTablesBlock {
  isMobile: boolean;
  isLoading: boolean;
  setDeals: (x: boolean) => void;
  deals: boolean;
  setSearch: (x: string) => void;
  openColumnsBottomSheet: () => void;
  handleColumn: () => void;
  dropdownRef: any;
  isDropdownOpen: boolean;
  headerOptions: string[];
  headers: string[];
  handleCheckboxChange: (l: string) => void;
  handleDragStart: (e: any, label: any) => void;
  handleDrop: (e: any, label: any) => void;
  filteredPortfolioData: IPortfolioDeals[] | IPortfolioStocks[];
  currencySign: string;
  onDeleteDeal: (itemId: string, index: number) => void;
  showCarouselIndex: number | null;
  setShowCarouselIndex: (x: number | null) => void;
  setShowDeleteDealModal: Dispatch<SetStateAction<boolean>>;
  setDeleteDealIndex: Dispatch<SetStateAction<number>>;
  setDeleteDealId: Dispatch<SetStateAction<string>>;
}

const PortfolioTablesBlock = ({
  isMobile,
  isLoading,
  setDeals,
  deals,
  setSearch,
  openColumnsBottomSheet,
  dropdownRef,
  handleColumn,
  isDropdownOpen,
  headerOptions,
  headers,
  handleCheckboxChange,
  handleDragStart,
  handleDrop,
  filteredPortfolioData,
  currencySign,
  onDeleteDeal,
  showCarouselIndex,
  setShowCarouselIndex,
  setShowDeleteDealModal,
  setDeleteDealIndex,
  setDeleteDealId,
}: IPortfolioTablesBlock) => {
  const [t] = useTranslation("global");

  const MobileHeaders: string[] = [
    t("portfolio.company"),
    t("portfolio.chart"),
    t("portfolio.more"),
  ];

  const renderRow = (
    item: IPortfolioDeals | IPortfolioStocks,
    index: number
  ) => {
    return (
      <Row
        item={item}
        index={index}
        headers={headers}
        isLoading={isLoading}
        currencySign={currencySign}
        showDeals={deals}
        deleteDeal={onDeleteDeal}
      />
    );
  };

  const renderMobileRow = (
    item: IPortfolioDeals | IPortfolioStocks,
    index: number
  ) => {
    return (
      <MobileRow
        item={item}
        index={index}
        isLoading={isLoading}
        showCarouselIndex={showCarouselIndex}
        setShowCarouselIndex={setShowCarouselIndex}
      />
    );
  };

  return (
    <div className="pb-8">
      {/* Table Top Section */}
      <div className="flex direction-row justify-between">
        <div>
          <span
            onClick={() => setDeals(!deals)}
            className={`inline-block text-[16px] text-base-content ${
              deals ? "bg-base-200" : "bg-base-300"
            } pb-4 pt-4 px-6 rounded-t-[0.75rem] mr-3 cursor-pointer border-t border-l border-r`}
          >
            {t("portfolio.stocks")}
          </span>
          <span
            onClick={() => setDeals(!deals)}
            className={`inline-block text-[16px] text-base-content ${
              deals ? "bg-base-300" : "bg-base-200"
            } pb-4 pt-4 px-6 rounded-t-[0.75rem] cursor-pointer border-t border-l border-r`}
          >
            {t("portfolio.deals")}
          </span>
        </div>

        <div className="flex direction-row items-center">
          {!isMobile && (
            <>
              <SearchBar
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                onClick={openColumnsBottomSheet}
                isMobile={false}
              />

              <div className="relative" ref={dropdownRef}>
                <div className="flex direction-row pl-4">
                  <button
                    className="flex direction-row items-center border bg-base-300 border-gray-300 ml-4 px-4 py-3 w-[8rem] rounded-[8px] text-base-content"
                    onClick={handleColumn}
                  >
                    <img
                      src={columns}
                      width={25}
                      height={25}
                      className="mr-3"
                      alt="Icon"
                    />
                    {t("portfolio.columns")}
                  </button>
                </div>

                {isDropdownOpen && (
                  <div className="w-[150%] absolute top-full left-[-3.75rem] mt-2 py-3 bg-base-300 border border-gray-300 rounded-[8px] shadow-md z-10 right-[100rem]">
                    {headerOptions.map((label, index) => (
                      <div className=" flex items-center justify-between px-1 py-1">
                        <div className="form-control" key={index}>
                          <label className="cursor-pointer label">
                            <input
                              type="checkbox"
                              checked={headers.includes(label)}
                              className="checkbox checkbox-sm checkbox-secondary"
                              onChange={() => handleCheckboxChange(label)}
                            />
                            <span className="label-text ml-3">{label}</span>
                          </label>
                        </div>
                        {/* Drag Icon */}
                        <div
                          className="cursor-pointer"
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, label)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => handleDrop(e, label)}
                        >
                          <img src={move} alt="drag" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={`overflow-x-auto mb-${isMobile ? "10" : ""}`}>
        {isMobile && (
          <div className="flex bg-base-300 justify-around border-r border-l py-2 rounded-tr-lg">
            <SearchBar
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              onClick={openColumnsBottomSheet}
              isMobile
            />
          </div>
        )}
        <GenericTable<Company>
          data={filteredPortfolioData}
          headers={isMobile ? MobileHeaders : headers}
          renderRow={isMobile ? renderMobileRow : renderRow}
          isLoading={isLoading}
          isMobile={isMobile}
          isDeals={deals}
          showCarouselIndex={showCarouselIndex}
          setShowDeleteDealModal={setShowDeleteDealModal}
          setDeleteDealIndex={setDeleteDealIndex}
          setDeleteDealId={setDeleteDealId}
          currencySign={currencySign}
          allHeaders={headers}
        />
      </div>
    </div>
  );
};

export default PortfolioTablesBlock;

interface Company {
  logo: string;
  companyName: string;
  chartImage: string;
  quantity: number;
  averagePrice: string;
  currentPrice: string;
  purchasePrice: string;
  dividends: string;
  dividendsYield: string;
}
