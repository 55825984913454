import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { NavArrowLeft } from 'iconoir-react';
import { toast } from 'react-toastify';
import {
  getPortfolioAssetsById,
  getPortfolioDealsById,
  getPortfolioStocksById,
  getPortfolioSummaryById,
  uploadCsvFile,
} from '../api/portfolio';
import {
  IPortfolioAssets,
  IPortfolioDeals,
  IPortfolioList,
  IPortfolioStocks,
  IPortfolioSummary,
} from '../models/portfolio';
import { getCurrencies } from '../api/account';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import profile from '../assets/icons/profile.svg';
import lock from '../assets/icons/lock.svg';
import upload_icon from '../assets/icons/upload_icon.svg';
import button_upload_icon from '../assets/icons/button_upload_icon.svg';
import buy from '../assets/icons/buy.svg';
import coin from '../assets/icons/coin.svg';
import wallet from '../assets/icons/wallet.svg';
import discount_deal from '../assets/icons/discount_deal.svg';
import note from '../assets/icons/note.svg';
import plus from '../assets/icons/plus.svg';
import paper from '../assets/icons/paper.svg';

const AddDealModal = ({
  isOpen,
  onClose,
  isMobile,
  portfolioList,
  id,
  setIsLoading,
  setPortfolioSummary,
  setPortfolioAssets,
  setPortfolioDeals,
  setPortfolioStocks,
}: AddDealModalProps) => {
  const IMPORT_TAB = 0;
  const ADD_DEAL_TAB = 1;
  const [dealTab, setDealTab] = useState(IMPORT_TAB);
  const [selectedBroker, setSelectedBroker] = useState('1');
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [isDragActive, setIsDragActive] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [uploadedFileSize, setUploadedFileSize] = useState<number | null>(null);
  const [currencies, setCurrencies] = useState([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>();

  const [t, i18n] = useTranslation('global');
  const modalId = 'add_deal_modal';

  const { handleSubmit, register } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    // You can perform further actions like API calls or state updates
  };

  const handleBrokerChange = (event: any) => {
    setSelectedBroker(event.target.value);
  };

  const handlePortfolioChange = (event: any) => {
    setSelectedPortfolio(event.target.value);
  };

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  useEffect(() => {
    getCurrencies()
      .then((currenciesResponse) => setCurrencies((currenciesResponse.data || []) as []))
      .catch((err) => console.log(err));
  }, []);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);

    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleUpload = async () => {
    const portfolioId = portfolioList.find((item) => item.title === selectedPortfolio)?.id;

    if (!portfolioId) return toast.warn('Please select portfolio');
    if (!file) return toast.warn('Please upload a file');

    setIsLoading(true);

    try {
      await uploadCsvFile(portfolioId, selectedBroker, file);

      const [
        portfolioSummaryByIdResponse,
        portfolioAssetsByIdResponse,
        portfolioDealsByIdResponse,
        portfolioStocksByIdResponse,
      ] = await Promise.all([
        getPortfolioSummaryById(id),
        getPortfolioAssetsById(id),
        getPortfolioDealsById(id),
        getPortfolioStocksById(id),
      ]);

      setPortfolioSummary(portfolioSummaryByIdResponse.data);
      setPortfolioAssets(portfolioAssetsByIdResponse.data);
      setPortfolioDeals(portfolioDealsByIdResponse.data);
      setPortfolioStocks(portfolioStocksByIdResponse.data);

      toast.success('File uploaded successfully');
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(`Error uploading file: ${error.response.data.message}`);
      } else {
        toast.error('Error uploading file');
      }
    } finally {
      setIsLoading(false);
      handleClearFile();
      onClose();
    }
  };

  const handleFiles = (files: FileList | null) => {
    if (files) {
      // Filter only .csv files
      const csvFiles = Array.from(files).filter((file) => file.name.toLowerCase().endsWith('.csv'));

      if (csvFiles.length > 0) {
        // Assuming you are handling only one file at a time
        const csvFile = csvFiles[0];
        setFile(csvFile);

        // Simulating file upload progress (you should replace this with your actual file upload logic)
        simulateFileUpload(csvFile);
      }
    }
  };

  const simulateFileUpload = (file: File) => {
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress !== null ? prevProgress + 10 : 10;

        if (newProgress >= 100) {
          clearInterval(interval);
          setUploadedFileName(file.name);
          setUploadedFileSize(file.size / 1000);
        }

        return newProgress;
      });
    }, 300);
  };

  const handleClick = () => {
    // Trigger click on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    handleFiles(files);
  };

  const handleClearFile = () => {
    setFile(null);
    setUploadedFileName(null);
    setUploadedFileSize(null);
  };
  return (
    <dialog id={modalId} className={`modal ${isMobile ? 'modal-bottom' : ''}`}>
      <div className={`modal-box ${isMobile ? 'max-h-[42rem]' : ''} overflow-x-hidden`}>
        {isMobile && dealTab === ADD_DEAL_TAB && (
          <button
            className="flex items-center justify-center absolute left-6 top-2 bg-base-300 text-base-content py-[5px] px-[12px] rounded-lg text-sm"
            onClick={onClose}
          >
            <NavArrowLeft width={15} height={15} />
            <span className="ml-1">Back</span>
          </button>
        )}
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>

        <div className="w-100 mt-6 lg:mt-4 px-[4px] py-[4px] h-12 bg-base-300 rounded-lg flex justify-between">
          <div
            onClick={() => setDealTab(IMPORT_TAB)}
            className={`flex items-center justify-center font-bold ${
              dealTab === IMPORT_TAB ? 'text-gray-400 bg-base-300' : 'text-purple-600 bg-base-100'
            } w-1/6 rounded-lg cursor-pointer`}   //w-1/2 - for half width
          >
            <span>{t('portfolioModal.import')}</span>
          </div>
          {/*<div
            onClick={() => setDealTab(ADD_DEAL_TAB)}
            className={`flex items-center justify-center font-bold ${
              dealTab === ADD_DEAL_TAB ? 'text-purple-600 bg-base-100' : 'text-gray-400 bg-base-300'
            } w-1/2 rounded-lg cursor-pointer`}
          >
            <span>{t('portfolioModal.addDeal')}</span>
          </div>*/}
        </div>
        {dealTab === IMPORT_TAB && (
          <>
            <div className={`flex lg:gap-2 ${isMobile ? 'flex-col' : ''}`}>
              <label className="form-control w-full max-w-xs">
                <div className="label py-0 mt-4">
                  <span className="label-text font-semibold">{t('portfolioModal.portfolio')}</span>
                </div>
                <div className="relative">
                  <img
                    src={lock}
                    alt="calendar"
                    className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                  />
                  <select
                    className={`${
                      isMobile ? 'w-[110%]' : ''
                    } appearance-none border border-gray-300 rounded-md p-2 pl-[3.5rem] pr-10 focus:outline-none focus:border-purple-600 bg-base-200`}
                    onChange={handlePortfolioChange}
                  >
                    <option selected disabled hidden>
                      {t('portfolioModal.choosePortfolio')}
                    </option>
                    {portfolioList.map((portfolio: IPortfolioList) => (
                      <option key={portfolio.id} value={portfolio.title}>
                        {portfolio.title}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
              <label className="form-control w-full max-w-xs">
                <div className="label py-0 mt-4">
                  <span className="label-text font-semibold">{t('portfolioModal.broker')}</span>
                </div>
                <div className="relative">
                  <img
                    src={profile}
                    alt="calendar"
                    className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                  />
                  <select
                    className={`${
                      isMobile ? 'w-[110%]' : ''
                    } appearance-none border border-gray-300 rounded-md p-2 pl-[3.5rem] pr-10 focus:outline-none focus:border-purple-600 bg-base-200`}
                    defaultValue={selectedBroker}
                    onChange={handleBrokerChange}
                  >
                    <option disabled selected hidden>
                      {t('portfolioModal.chooseBroker')}
                    </option>
                    <option value="1">Broker 1</option>
                    <option value="2">Broker 2</option>
                  </select>
                </div>
              </label>
            </div>

            {!uploadedFileName && (
              <div
                className={`flex justify-center flex-col items-center h-[80%] w-100 rounded-lg py-[6rem] mt-4 border-2 border-dashed ${
                  isDragActive ? 'bg-gray-200' : ''
                }`}
                onDragEnter={handleDragEnter}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClick}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                  accept=".csv"
                />
                {progress !== null && progress < 100 ? (
                  <>
                    <div className="text-center mb-2">{progress}% loaded</div>
                    <div className="w-80 h-3 bg-[#e5e7eb] relative rounded-lg">
                      <div
                        className="h-full bg-[#8C56E1] absolute rounded-lg"
                        style={{
                          width: `${progress}%`,
                          transition: 'width 0.5s ease-in-out',
                        }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <>
                    {!isDragActive && (
                      <>
                        <img src={upload_icon} width={30} height={30} className="mr-4 mb-2" alt="Icon" />
                        <div>
                          {t('portfolioModal.drag')}
                          <span className="text-[#8DC63F]">{t('portfolioModal.chooseFile')}</span>
                        </div>
                        <p className="text-[#8395A7]">{t('portfolioModal.csv')}</p>
                      </>
                    )}
                    {isDragActive && <p className="text-[#8395A7]">{t('portfolioModal.drop')}</p>}
                  </>
                )}
              </div>
            )}
            {file && uploadedFileName && uploadedFileSize && (
              <div className="w-100 mt-4 px-[4px] py-[4px] h-16 bg-[#F3F4F6] rounded-lg flex justify-between">
                <div className={`flex items-center`}>
                  <img className="ml-2" src={paper} width={50} height={50} alt="paper" />
                  {!isMobile && <p className="font-bold ml-3 text-sm lg:text-md">{uploadedFileName}</p>}
                  {isMobile && (
                    <div className="ml-2">
                      <p className="font-bold text-sm lg:text-md">{uploadedFileName}</p>
                      <p className="font-bold text-sm text-gray-400 lg:text-md">
                        {uploadedFileSize}
                        <span className="ml-1">KB</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-4">
                  {!isMobile && (
                    <p className="font-bold text-sm lg:text-md">
                      {uploadedFileSize}
                      <span className="ml-1">KB</span>
                    </p>
                  )}
                  <button className="btn btn-sm btn-circle btn-ghost" onClick={handleClearFile}>
                    ✕
                  </button>
                </div>
              </div>
            )}
            <div className={`flex justify-center ${isMobile ? 'flex-col' : ''} mt-4 gap-2`}>
              <button
                className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[7px] lg:w-35 h-15 bg-[#8C56E1] text-[#ffffff]"
                onClick={handleUpload}
              >
                <span className="flex flex-row items-center justify-center pl-3 pr-3">
                  <img src={button_upload_icon} alt="portfolio-logo" className="w-6 h-6 pt-[4px] pb-[4px] mr-2" />
                  {t('button.upload')}
                </span>
              </button>
              <button
                className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6 text-base-content bg-base-300"
                onClick={onClose}
              >
                {t('button.cancel')}
              </button>
            </div>
          </>
        )}
        {dealTab === ADD_DEAL_TAB && !isMobile && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex gap-4 mb-2">
                <label className="form-control w-[48%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Portfolio</span>
                  </div>
                  <img
                    src={lock}
                    alt="calendar"
                    className="absolute left-[29px] top-[23.5%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <select
                    {...register('portfolio', { required: 'Portfolio is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200"
                  >
                    <option selected disabled hidden>
                      {t('portfolioModal.choosePortfolio')}
                    </option>
                    {portfolioList.map((portfolio: IPortfolioList) => (
                      <option key={portfolio.id} value={portfolio.title}>
                        {portfolio.title}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="form-control w-[49%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Company ticker</span>
                  </div>
                  <img
                    src={profile}
                    alt="calendar"
                    className="absolute left-[53%] lg:left-[51%] top-[23.5%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <input
                    {...register('companyTicker', { required: 'Company ticker is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    type="text"
                    maxLength={20}
                    placeholder="Enter ticker"
                  />
                </label>
              </div>
              <div className="flex gap-4 mb-2">
                <label className="form-control w-[31%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Buy or sell</span>
                  </div>
                  <img
                    src={buy}
                    alt="calendar"
                    className="absolute left-[24px] top-[37.5%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <select
                    {...register('buyorsell', { required: 'Buy or Sell is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-10 focus:outline-none focus:border-purple-600 bg-base-200"
                  >
                    <option disabled selected hidden>
                      {t('portfolioModal.choose')}
                    </option>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </label>
                <label className="form-control w-[31%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Currency</span>
                  </div>
                  <img
                    src={coin}
                    alt="calendar"
                    className="absolute left-[140px] lg:left-[185px] top-[37.5%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <select
                    {...register('currency', { required: 'currency is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-10 focus:outline-none focus:border-purple-600 bg-base-200"
                  >
                    <option value="Choose" selected disabled hidden>
                      Choose
                    </option>
                    {currencies.map((currency, i) => (
                      <option key={i} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="form-control w-[31%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Date</span>
                  </div>
                  <input
                    {...register('date', { required: 'Date is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    type="date"
                    placeholder="Choose Date"
                  />
                </label>
              </div>
              <div className="flex gap-4 mb-2">
                <label className="form-control w-[48%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Amount</span>
                  </div>
                  <img
                    src={wallet}
                    alt="calendar"
                    className="absolute left-[29px] top-[51.5%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <input
                    {...register('amount', { required: 'amount is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    type="number"
                    min={0}
                    maxLength={20}
                    placeholder="Enter amount"
                  />
                </label>
                <label className="form-control w-[49%] max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Commission</span>
                  </div>
                  <img
                    src={discount_deal}
                    alt="calendar"
                    className="absolute left-[52%] top-[51.5%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <input
                    {...register('commission', { required: 'commission is required' })}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-10 lg:pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    type="text"
                    maxLength={20}
                    placeholder="Enter commission"
                  />
                </label>
              </div>
              <div className="flex mb-2">
                <label className="form-control w-[100%]">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold">Note</span>
                  </div>
                  <img
                    src={note}
                    alt="calendar"
                    className="absolute left-[29px] top-[65%] transform -translate-y-1/2 w-[35px] h-[18px] border-r "
                  />
                  <textarea
                    {...register('note')}
                    className="appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-blue-500 w-[100%] bg-base-200 text-base-content"
                    rows={4}
                    cols={100}
                    placeholder="Enter note"
                  ></textarea>
                </label>
              </div>
              <div className="flex direction-row mt-2">
                <input
                  {...register('writeoffmoney')}
                  className="border border-gray-300 rounded focus:outline-none focus:border-purple-600 mr-1 cursor-pointer"
                  type="checkbox"
                />
                <div className="label">
                  <span className="label-text font-semibold">Write off money</span>
                </div>
              </div>
              <div className={`flex justify-center ${isMobile ? '' : ''} mt-4 gap-2`}>
                <button
                  type="submit"
                  className="focus:outline-none border border-gray-300 px-2 py-2 rounded-[7px] w-32 h-15 bg-[#8C56E1] text-[#ffffff] "
                >
                  <span className="flex flex-row justify-center items-center pr-2">
                    <img src={plus} alt="portfolio-logo" className="w-6 h-6 pt-[4px] pb-[4px]" />
                    Add
                  </span>
                </button>
                <button
                  className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] w-32 h-15 lg:ml-6 bg-base-300 text-base-content"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
        {dealTab === ADD_DEAL_TAB && isMobile && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 mb-2">
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-4">
                    <span className="label-text font-semibold mb-1">Portfolio</span>
                  </div>
                  <div className="relative">
                    <img
                      src={lock}
                      alt="calendar"
                      className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <select
                      {...register('portfolio', { required: 'Portfolio is required' })}
                      className={`w-[110%] appearance-none border border-gray-300 rounded-md p-2 pl-[3.5rem] pr-10 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content`}
                    >
                      <option selected disabled hidden>
                        Choose portfolio
                      </option>
                      {portfolioList.map((portfolio: IPortfolioList) => (
                        <option key={portfolio.id} value={portfolio.title}>
                          {portfolio.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </label>
              </div>
              <div className="flex gap-2 mb-2">
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-2">
                    <span className="label-text font-semibold mb-1">Company ticker</span>
                  </div>
                  <div className="relative">
                    <img
                      src={profile}
                      alt="calendar"
                      className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <input
                      {...register('companyTicker', { required: 'Company ticker is required' })}
                      className=" w-[100%] appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                      type="text"
                      maxLength={20}
                      placeholder="Enter ticker"
                    />
                  </div>
                </label>
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-2">
                    <span className="label-text font-semibold mb-1">Buy or sell</span>
                  </div>
                  <div className="relative">
                    <img
                      src={buy}
                      alt="calendar"
                      className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <select
                      {...register('buyorsell', { required: 'Buy or Sell is required' })}
                      className="w-[100%] appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    >
                      <option disabled selected hidden>
                        Choose
                      </option>
                      <option value="buy">Buy</option>
                      <option value="sell">Sell</option>
                    </select>
                  </div>
                </label>
              </div>
              <div className="flex gap-2 mb-2">
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-2">
                    <span className="label-tex font-semibold mb-1t">Currency</span>
                  </div>
                  <div className="relative">
                    <img
                      src={coin}
                      alt="calendar"
                      className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <select
                      {...register('currency', { required: 'currency is required' })}
                      className="w-[100%] appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    >
                      <option value="Choose" selected disabled hidden>
                        Choose
                      </option>
                      {currencies.length &&
                        currencies.map((currency, i) => (
                          <option key={i} value={currency}>
                            {currency}
                          </option>
                        ))}
                    </select>
                  </div>
                </label>
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-2">
                    <span className="label-text font-semibold mb-1">Date</span>
                  </div>
                  <input
                    {...register('date', { required: 'Date is required' })}
                    className="w-[100%] appearance-none border border-gray-300 rounded-md p-2 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                    type="date"
                    placeholder="Choose Date"
                  />
                </label>
              </div>
              <div className="flex flex-col mb-2">
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-2">
                    <span className="label-text font-semibold mb-1">Amount</span>
                  </div>
                  <div className="relative">
                    <img
                      src={wallet}
                      alt="calendar"
                      className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <input
                      {...register('amount', { required: 'amount is required' })}
                      className=" w-[110%] appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                      type="number"
                      min={0}
                      maxLength={20}
                      placeholder="Enter amount"
                    />
                  </div>
                </label>
                <label className="form-control w-full max-w-xs">
                  <div className="label py-0 mt-2">
                    <span className="label-text font-semibold mb-1">Commission</span>
                  </div>
                  <div className="relative">
                    <img
                      src={discount_deal}
                      alt="calendar"
                      className="absolute absolute top-1/2 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <input
                      {...register('commission', { required: 'commission is required' })}
                      className=" w-[110%] appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 bg-base-200 text-base-content"
                      type="text"
                      maxLength={20}
                      placeholder="Enter commission"
                    />
                  </div>
                </label>
              </div>
              <div className="flex mb-2">
                <label className="form-control w-[100%]">
                  <div className="label py-0 mt-2">
                    <span className="label-text font-semibold mb-1">Note</span>
                  </div>
                  <div className="relative">
                    <img
                      src={note}
                      alt="calendar"
                      className="absolute absolute top-5 left-2 transform -translate-y-1/2 w-[35px] h-[18px] border-r"
                    />
                    <textarea
                      {...register('note')}
                      className="appearance-none border border-gray-300 rounded-md p-2 pl-12 focus:outline-none focus:border-purple-600 w-[100%] bg-base-200 text-base-content"
                      rows={3}
                      cols={100}
                      placeholder="Enter note"
                    ></textarea>
                  </div>
                </label>
              </div>
              <div className="flex direction-row mt-2">
                <input
                  {...register('writeoffmoney')}
                  className="border border-gray-300 rounded focus:outline-none focus:border-purple-600 mr-1 cursor-pointer"
                  type="checkbox"
                />
                <div className="label">
                  <span className="label-text">Write off money</span>
                </div>
              </div>
              <div className={`flex justify-center ${isMobile ? 'flex-col' : ''} mt-4 gap-2`}>
                <button className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[7px] lg:w-32 h-15 bg-[#8C56E1] text-[#ffffff] ">
                  <span className="flex flex-row justify-center item-center pr-2">
                    <img src={plus} alt="portfolio-logo" className="w-6 h-6 pt-[4px] pb-[4px]" />
                    Add
                  </span>
                </button>
                <button
                  className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6 bg-base-300 text-base-content"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
    </dialog>
  );
};

export default AddDealModal;

interface AddDealModalProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean;
  portfolioList: IPortfolioList[] | [];
  id: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setPortfolioSummary: Dispatch<SetStateAction<IPortfolioSummary | null>>;
  setPortfolioAssets: Dispatch<SetStateAction<IPortfolioAssets[]>>;
  setPortfolioDeals: Dispatch<SetStateAction<IPortfolioDeals[]>>;
  setPortfolioStocks: Dispatch<SetStateAction<IPortfolioStocks[]>>;
}
