import mainLogo from '../assets/logos/logo_light.png';
import logo from '../assets/logos/logo.png';
import banner from '../assets/auth/banner.png';
import google from '../assets/auth/google.svg';
import googleMobile from '../assets/auth/google-mobile.svg';
import facebook from '../assets/auth/facebook.svg';
import facebookMobile from '../assets/auth/facebook-mobile.svg';
import linkedIn from '../assets/auth/linkedIn.svg';
import linkedInMobile from '../assets/auth/linkedIn-mobile.svg';
import { useEffect, useState } from 'react';
import { TAuthSections } from '../models/auth';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { signinUser } from '../api/account';
import FacebookLogin from 'react-facebook-login';
import ENV from '../environment';
import '../styles/auth.scss';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useGoogleLogin } from '@react-oauth/google';
import AuthForm from './AuthForm';

const Auth = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const loginParam = searchParams.get('login');

  const [authSection, setAuthSection] = useState<TAuthSections>(loginParam ? 'LOGIN' : 'REGISTER');
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1350);
  document?.querySelector('html')?.setAttribute('data-theme', 'light');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1350);
    };

    window.scrollTo(0, 0);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (resp) => {
      const accessToken = resp.access_token;

      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (userInfoResponse.ok) {
        const userInfo = await userInfoResponse.json();

        const userData = {
          id: userInfo.id,
          provider: 1,
          userName: userInfo.name,
          email: userInfo.email,
          image: userInfo.picture,
          language: userInfo.locale,
        };

        signinUser(userData).then((res) => {
          navigate('/app/dashboard');
        });
      } else {
        console.error('Failed to fetch user info from Google API');
      }
    },
  });

  const responseFacebook = (response: any) => {
    console.log(response);
    // Handle the Facebook login response here
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: ENV.LINKED_IN_CLIENT_ID,
    redirectUri: 'https://finberryuat.netlify.app',
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <div className="flex flex-col h-screen">
        <header className="lg:flex py-2 px-10 text-white text-center shadow-lg hidden">
          <div className="flex items-center justify-center">
            <img
              className="cursor-pointer"
              src={mainLogo}
              onClick={() => navigate('/')}
              width={200}
              height={200}
              alt="logo"
            />
          </div>
        </header>

        {/* Main Content */}
        <div className="flex-1 lg:flex bg-gray-100">
          {/* Left half: Form */}
          <div className="lg:w-1/2 lg:px-20 lg:py-0 p-5 items-center top-1">
            <div className="w-full mx-auto lg:px-20">
              {/* <button className='btn btn-info lg:flex hidden mb-10 py-0'>Back</button> */}
              <img
                className="lg:hidden mb-4 cursor-pointer"
                onClick={() => navigate('/')}
                src={logo}
                width={70}
                height={70}
                alt="mobile-logo"
              />
              <h2 className="lg:text-[32px] text-[24px] font-bold mb-2 leading-[150%]">
                {authSection === 'REGISTER'
                  ? 'Get Started Now'
                  : authSection === 'LOGIN'
                  ? 'Hi, Welcome back!'
                  : authSection === 'RESETPASS'
                  ? 'Forgot password'
                  : ''}
              </h2>
              <h3 className="lg:text-[16px] text-[14px] mb-4 text-gray-500 leading-[150%]">
                {authSection === 'REGISTER'
                  ? 'Enter your credentials to access your account'
                  : authSection === 'LOGIN'
                  ? 'Please enter your details'
                  : authSection === 'RESETPASS'
                  ? 'Enter your email to restore the password'
                  : ''}
              </h3>

              <div className="flex gap-[3rem]">
                <button
                  className="bg-white px-2 py-1 h-[50px] rounded-md border border-gray-300"
                  onClick={() => googleLogin()}
                >
                  <img
                    src={isMobile ? googleMobile : google}
                    height={40}
                    width={110}
                    style={{ height: '40px' }}
                    alt="google"
                  />
                </button>

                <FacebookLogin
                  appId={ENV.FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  cssClass="bg-white lg:py-0 px-2 py-1 h-[50px] rounded-md border border-gray-300 flex justify-center items-center"
                  icon={
                    <img
                      src={isMobile ? facebookMobile : facebook}
                      height={30}
                      width={110}
                      style={{ height: '40px' }}
                      alt="facebook"
                    />
                  }
                  textButton=""
                />
                <button
                  className="bg-white px-2 py-1 h-[50px] rounded-md border border-gray-300"
                  onClick={linkedInLogin}
                >
                  <img
                    src={isMobile ? linkedInMobile : linkedIn}
                    height={40}
                    width={110}
                    style={{ height: '40px' }}
                    alt="linkedIn"
                  />
                </button>
              </div>
              {/*<AuthForm />*/}
              
            </div>
          </div>

          {/* Right half: Image */}
          <div className="hidden lg:flex items-center justify-center w-1/2">
            <img className="object-cover max-h-[1200px]" src={banner} alt="Auth Banner" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
