import { useTranslation } from "react-i18next";

const Analytics = ({ show }: IAnalytics) => {
  const [t] = useTranslation("global");

  return show ? (
    <div className="text-[2rem] pt-[15rem] h-100 text-accent flex justify-center items-center">
      <h1>{t("analytics.coming_soon_message")}</h1>
    </div>
  ) : null;
};

export default Analytics;

interface IAnalytics {
  show: Boolean;
}
