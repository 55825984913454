import banner from '../../assets/dashboard/bg.png';
import { Mail, User, Key, FloppyDisk } from 'iconoir-react';
import { useEffect, useState } from 'react';
import { getUserSettings, setUserSettings } from '../../api/user';
import { IUserSettings } from '../../models/user';
import ChangePassModal from '../../Modals/ChangePassModal';
import { logoutUser } from '../../api/account';
import { useNavigate } from 'react-router-dom';
import logoutIcon from '../../assets/icons/logout.svg';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface SettingsProps {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
}

const Settings = ({ setTheme, theme }: SettingsProps) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const [settingsTheme, setSettingsTheme] = useState('');
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    getUserSettings()
      .then((res: IUserSettings) => {
        setEmail(res.email);
        setName(res.name);
        if (res.theme === 0) setSettingsTheme('light');
        else setSettingsTheme('dark');
      })
      .catch((error) => {
        toast.error('Request Failed. Please try again later :(');
        console.error(error);
      });
  }, []);

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsTheme(event.target.value);
  };

  const updateTheme = () => {
    const settings = {
      theme: settingsTheme === 'light' ? 0 : 1,
      email,
      name,
    };
    setUserSettings(settings)
      .then(() => {
        setTheme(settingsTheme);
        toast.success('Theme Updated.');
      })
      .catch((error) => {
        toast.error('Request Failed. Please try again later :(');
        console.error(error);
      });
  };

  const closeChangePassModal = () => {
    setShowChangePassModal(false);
  };

  const logout = () => {
    logoutUser();
    navigate('/');
  };

  return (
    <>
      <ChangePassModal isOpen={showChangePassModal} onClose={closeChangePassModal} />
      <div className="p-0">
        <div className="relative shadow-md">
          <img src={banner} alt="Settings Background" className="w-full h-[20dvh] object-cover" />
          <div className="absolute bottom-8 left-8 p-4 text-neutral">
            <h3 className="text-[32px] font-bold">{t('settings.header')}</h3>
            <p className="text-[16px] mt-3 text-gray-500">{t('settings.subheading')}</p>
          </div>
        </div>

        <div className="mt-10 px-5 lg:mt-40 lg:pl-40 lg:w-[70rem]">
          <div className="flex flex-col lg:flex-row items-start justify-between w-full">
            <label htmlFor="email" className="text-base-content text-[16px] font-bold mb-2 lg:w-[10vw]">
              {t('settings.email')}
            </label>
            <div className="input input-bordered flex items-center gap-2 focus:border-none focus:outline-none w-full lg:w-[50%] bg-base-200">
              <Mail className="w-5 h-5 opacity-70" />
              <input
                type="email"
                id="email"
                value={email}
                disabled
                className="grow border-none focus:border-none focus:outline-none bg-base-200"
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-start justify-between w-full mt-5">
            <label htmlFor="username" className="text-base-content text-[16px] font-bold mb-2 lg:w-[10vw]">
              {t('settings.username')}
            </label>
            <div className="input input-bordered flex items-center gap-2 focus:border-none focus:outline-none w-full lg:w-[50%] bg-base-200">
              <User className="w-5 h-5 opacity-70" />
              <input
                type="text"
                id="username"
                value={name}
                disabled
                className="grow border-none focus:border-none focus:outline-none bg-base-200"
              />
            </div>
          </div>
          {/*<div className="mt-5 flex flex-col lg:flex-row items-start justify-between w-full">
            <label
              htmlFor="password"
              className="w-[10vw] flex-shrink-0 text-base-content text-[16px] font-bold mb-2 lg:mb-0 lg:mr-4"
            >
              {t('settings.password')}
            </label>
            <div className="flex flex-col lg:flex-row items-center justify-between w-full lg:w-[50%]">
              <label className="input input-bordered flex items-center gap-2 focus:border-none focus:outline-none w-full lg:w-[57%] bg-base-200 mb-4 lg:mb-0">
                <Key className="w-5 h-5 opacity-70" />
                <input
                  type="password"
                  id="password"
                  value={'12345678'}
                  disabled
                  className="grow border-none focus:border-none focus:outline-none bg-base-200"
                />
              </label>
              <button
                className="flex focus:outline-none border border-gray-300 bg-base-200 px-6 py-2 rounded font-medium lg:ml-4 mt-10 lg:mt-0 w-full justify-center"
                onClick={() => setShowChangePassModal(true)}
              >
                {t('settings.changePass')}
              </button>
            </div>
  </div>*/}

          <div className="border-b border-dashed border-gray-300 border-3 w-full lg:my-20 my-10 shadow-md"></div>

          <div className="flex flex-col lg:flex-row justify-between w-full mt-5">
            <label
              htmlFor="interface"
              className="lg:w-[10vw] w-auto flex-shrink-0 text-base-content text-[16px] font-bold mb-4 lg:mb-0"
            >
              {t('settings.theme')}
            </label>
            <div className="flex flex-col items-start justify-between w-full lg:w-[50%]">
              <div className="flex items-center text-base-content text-[16px] mb-4">
                <input
                  type="radio"
                  id="lightTheme"
                  value={'light'}
                  checked={settingsTheme === 'light'}
                  onChange={handleThemeChange}
                  className="mr-2 radio radio-secondary"
                />
                <label htmlFor="lightTheme">{t('settings.light')}</label>
              </div>
              <div className="flex items-center text-base-content text-[16px] mb-4">
                <input
                  type="radio"
                  id="darkTheme"
                  value={'dark'}
                  checked={settingsTheme === 'dark'}
                  onChange={handleThemeChange}
                  className="mr-2 radio radio-secondary"
                />
                <label htmlFor="darkTheme">{t('settings.dark')}</label>
              </div>

              <button
                className="btn btn-primary text-white py-2 px-10 w-full lg:w-[15vw] my-5 lg:my-0"
                onClick={updateTheme}
              >
                <FloppyDisk />
                {t('settings.save')}
              </button>
            </div>
          </div>

          <div className="border-b border-dashed border-gray-300 border-3 w-full mt-4 shadow-md lg:hidden"></div>

          <button
            className="flex items-center focus:outline-none border border-gray-300 bg-base-200 px-6 py-2 rounded font-medium mt-5 mb-20 w-full justify-center lg:hidden"
            onClick={logout}
          >
            <img src={logoutIcon} width={16} height={16} className="mr-2" alt="Icon" />
            {t('nav.logout')}
          </button>
        </div>
      </div>
    </>
  );
};

export default Settings;
