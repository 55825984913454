import { useEffect } from 'react';
import { IPortfolioDeals } from '../models/portfolio';

interface DeleteDealModalProps {
  isOpen: boolean;
  deals: IPortfolioDeals[];
  onClose: () => void;
  onDelete: (index: number) => void;
  dealIndexToDelete: number; // Add property to store the index to be deleted
  isMobile: boolean;
}

const DeleteDealModal = ({ isOpen, deals, onClose, onDelete, dealIndexToDelete, isMobile }: DeleteDealModalProps) => {
  const modalId = 'delete_deal_modal';

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  return (
    <dialog id={modalId} className={`modal ${isMobile ? 'modal-bottom' : ''}`}>
      <div className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h3 className="font-bold text-lg text-center">Delete Deal</h3>
        <p className="py-8 text-center">Are you sure you want to delete this deal?</p>
        <div className="flex justify-center gap-2">
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] w-32 h-15 lg:ml-6  bg-[#8C56E1] text-[#ffffff]"
            onClick={onClose}
          >
            No
          </button>
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] w-32 h-15 lg:ml-6 text-[#000000]"
            onClick={() => onDelete(dealIndexToDelete)}
          >
            Yes
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
    </dialog>
  );
};

export default DeleteDealModal;
