import { useRef, useState } from "react";
import title from "../assets/main/title.png";
import title2 from "../assets/main/title2.png";
import title2_mobile from "../assets/main/title2_mobile.png";
import innovate from "../assets/main/innovate.png";
import pricing from "../assets/main/pricing.png";
import sharepass from "../assets/main/sharepass.png";
import transparency from "../assets/main/transparency.png";
import i_button from "../assets/main/i_button.png";
import s_button from "../assets/main/s_button.png";
import t_button from "../assets/main/t_button.png";
import review_title from "../assets/main/review_title.png";
import numbers from "../assets/main/numbers.png";
import numbers_mobile from "../assets/main/numbers_mobile.png";
import subtitle from "../assets/main/subtitle.png";
import "../styles/main.scss";
import plan1 from "../assets/dashboard/pricing/plan01.svg";
import plan2 from "../assets/dashboard/pricing/plan02.png";
import plan3 from "../assets/dashboard/pricing/plan03.svg";

import PlanCard from "../components/Main/PlanCard";
import ExtendedPlanCard from "../components/Main/ExtendedPlanCard";
import MainBanner, { useBreakPoint } from "../components/Main/MainBanner";
import Testimonials from "../components/Main/Testimonials";
import Footer from "../components/Main/Footer";
import TitleAndBar from "../components/Main/TitleAndBar";

const Main = () => {
  const [toggle, setToggle] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [windowWidth] = useBreakPoint();

  const handleToggle = () => {
    setToggle(toggle === 0 ? 1 : 0);
  };

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
  };

  const pricingRef = useRef(null);

  const scrollToPricing = () => {
    if (pricingRef.current) {
      (pricingRef.current as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <MainBanner />

      <div className="bg-white">
        <div className="flex justify-center">
          <img src={title} alt="title" className=" main-title" />
        </div>

        <div className="flex">
          <img src={subtitle} alt="subtitle" className=" main-subtitle" />
        </div>

        <TitleAndBar />

        <div className="flex justify-center  mt-[4rem]  mb-[4rem]">
          <img
            src={windowWidth >= 500 ? title2 : title2_mobile}
            alt="title"
            className={windowWidth >= 500 ? "w-1/2" : "w-4/5"}
          />
        </div>

        <div className="main-container">
          {/* First container */}
          <div className=" container container1">
            <div className="icon">
              <img src={innovate} alt="innovate" className="" />
            </div>
            <div className="text">
              Inovate
              <div className="subtext">Constant updates and new features</div>
            </div>
            <div className="button">
              <a href="/app/auth">
                <img src={i_button} alt="i_button" className="" />
              </a>
            </div>
            <div className="innovate">INOVATE</div>
          </div>

          {/* Second container */}
          <div className=" container container2">
            <div className="icon">
              <img src={sharepass} alt="sharepass" className="" />
            </div>
            <div className="text">
              SharePass
              <div className="subtext">
                Free subscription with unlimited shares
              </div>
            </div>
            <div className="button">
              <a href="/app/auth">
                <img src={s_button} alt="s_button" className="" />
              </a>
            </div>
            <div className="sharepass">SHAREPASS</div>
          </div>

          {/* Third container */}
          <div className="container container3">
            <div className="icon">
              <img src={transparency} alt="transparency" className="" />
            </div>
            <div className="text">
              Transparency
              <div className="subtext">
                Clear and convenient analytics and statistics
              </div>
            </div>
            <div className="button">
              <a href="/app/auth">
                <img src={t_button} alt="t_button" className="" />
              </a>
            </div>
            <div className="transparency">TRANSPARENCY</div>
          </div>

          {/* Black background container */}
          <div className="middle-container">
            <div className="small-black-container">
              <img
                src={review_title}
                alt="review_title"
                className="black-container-img"
              />
              {/* Render testimonials horizontally */}
              <Testimonials />
              <div className="try-now">
                <button className="button" onClick={(e) => {
                  e.preventDefault();
                  window.location.href='/app/auth';
                  }}>Try it now</button>
              </div>
              <div className="numbers">
                {/* <img src={numbers} alt="numbers" className="numbers-image" /> */}
                <img
                  src={windowWidth >= 500 ? numbers : numbers_mobile}
                  alt="numbers"
                  className="numbers-image"
                />
              </div>
            </div>

            {/* Pricing */}
            <div className="pricing" ref={pricingRef}>
              <img src={pricing} alt="pricing" />
            </div>
            <div className="form-control  mt-10">
              <label className="cursor-pointer label justify-center">
                <span
                  className={`label-text text-[16px]  mr-[1rem] text-black ${
                    toggle === 0 ? "font-bold" : ""
                  }`}
                >
                  Monthly
                </span>
                <input
                  type="checkbox"
                  className="toggle toggle-secondary "
                  checked={toggle === 1}
                  onChange={handleToggle}
                />
                <span
                  className={`label-text text-[16px] flex items-center ml-[1rem] text-black ${
                    toggle === 1 ? "font-bold" : ""
                  }`}
                >
                  Annually
                  <div className="rounded-lg ml-2">
                    <p className="text-purple-500">
                      <span className="rounded-lg px-3  border border-purple-500">
                        -50%
                      </span>
                    </p>
                  </div>
                </span>
              </label>
            </div>

            <div className="my-20 w-full justify-center items-start hidden lg:flex gap-[40px]">
              <div className="w-1/4 mt-10">
                <PlanCard
                  backgroundImage={plan3}
                  title="Basic"
                  priceMonth="10"
                  priceYear="80"
                  isMonth={toggle ===0}
                  height="500px"
                  features={[
                    "Potential for high returns",
                    "Diversification",
                    "Ownership in companies",
                    "Liquidity",
                    "Protection against inflation",
                  ]}
                />
              </div>

              <div className="w-1/4">
                <ExtendedPlanCard
                  backgroundImage={plan2}
                  title="Extended"
                  priceMonth="15"
                  priceYear="130"
                  isMonth={toggle ===0}
                  height="500px"
                  features={[
                    "Potential for high returns",
                    "Diversification",
                    "Ownership in companies",
                    "Liquidity",
                    "Protection against inflation",
                  ]}
                />
              </div>

              <div className="w-1/4 mt-10">
                <PlanCard
                  backgroundImage={plan3}
                  title="Standart"
                  priceMonth="12"
                  priceYear="120"
                  isMonth={toggle ===0}
                  height="500px"
                  features={[
                    "Potential for high returns",
                    "Diversification",
                    "Ownership in companies",
                    "Liquidity",
                    "Protection against inflation",
                  ]}
                />
              </div>
            </div>

            <div className="lg:hidden">
              <div className="carousel w-full mt-5">
                <div
                  id="item1"
                  className="carousel-item relative flex justify-center w-[100%]"
                >
                  <PlanCard
                    backgroundImage={plan1}
                    title="Basic"
                    priceMonth="10"
                    priceYear="80"
                    isMonth={toggle ===0}
                    height="160px"
                    features={[
                      "Potential for high returns",
                      "Diversification",
                      "Ownership in companies",
                      "Liquidity",
                      "Protection against inflation",
                    ]}
                  />
                </div>
                <div
                  id="item2"
                  className="carousel-item relative flex justify-center w-[100%]"
                >
                  <ExtendedPlanCard
                    backgroundImage={plan2}
                    title="Extended"
                    priceMonth="15"
                    priceYear="130"
                    isMonth={toggle ===0}
                    height="220px"
                    features={[
                      "Potential for high returns",
                      "Diversification",
                      "Ownership in companies",
                      "Liquidity",
                      "Protection against inflation",
                    ]}
                  />
                </div>
                <div
                  id="item3"
                  className="carousel-item relative flex justify-center w-[100%]"
                >
                  <PlanCard
                    backgroundImage={plan3}
                    title="Standart"
                    priceMonth="12"
                    priceYear="120"
                    isMonth={toggle ===0}
                    height="160px"
                    features={[
                      "Potential for high returns",
                      "Diversification",
                      "Ownership in companies",
                      "Liquidity",
                      "Protection against inflation",
                    ]}
                  />
                </div>
              </div>
              <div className="flex justify-center w-full py-2 gap-2">
                <a
                  href="#item1"
                  onClick={() => handleItemClick(1)}
                  className={`flex items-center justify-center rounded-full ${
                    selectedItem === 1
                      ? "bg-primary w-5 h-5"
                      : "bg-primary opacity-50 w-4 h-4"
                  }`}
                ></a>
                <a
                  href="#item2"
                  onClick={() => handleItemClick(2)}
                  className={`flex items-center justify-center rounded-full ${
                    selectedItem === 2
                      ? "bg-primary w-5 h-5"
                      : "bg-primary opacity-50 w-4 h-4"
                  }`}
                ></a>
                <a
                  href="#item3"
                  onClick={() => handleItemClick(3)}
                  className={`flex items-center justify-center rounded-full ${
                    selectedItem === 3
                      ? "bg-primary w-5 h-5"
                      : "bg-primary opacity-50 w-4 h-4"
                  }`}
                ></a>
              </div>
            </div>

            {/* Headings */}
            <Footer scrollToPricing={scrollToPricing} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
