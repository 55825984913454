import '../../styles/nav.scss';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import logoDark from '../../assets/logos/logo_dark.png';
import logoLight from '../../assets/logos/logo_light.png';
import logoBorder from '../../assets/logos/logo_border.png';
import portfolio from '../../assets/icons/new_portfolio.svg';
import graph from '../../assets/icons/graph.svg';
import logo from '../../assets/logos/logo.png';
import pricing from '../../assets/icons/pricing.svg';
import settings from '../../assets/icons/settings.svg';
import support from '../../assets/icons/support.svg';
import sidebarCollapse from '../../assets/icons/sidebar_toggle.svg';
import logoutIcon from '../../assets/icons/logout.svg';

import CreatePortfolioModal from '../../Modals/CreatePortfolio';
import { getCurrencies, getLanguages, getUserDataLocally, logoutUser } from '../../api/account';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IPortfolioList } from '../../models/portfolio';
import { NavArrowDown, NavArrowLeft } from 'iconoir-react';
import { changeCurrency, changeLanguage } from '../../api/user';

interface NavbarProps {
  children: ReactNode;
  theme: string;
  portfolioList: IPortfolioList[];
  setPortfolioList: Dispatch<SetStateAction<IPortfolioList[]>>;
  handleCurrencyChange: any;
  selectedCurrency: string;
}

const Navbar = ({
  children,
  theme,
  portfolioList,
  setPortfolioList,
  handleCurrencyChange,
  selectedCurrency,
}: NavbarProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1026);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(getUserDataLocally()?.language || '');

  const [showCreatePortfolioModal, setShowCreatePortfolioModal] = useState(false);
  const location = useLocation().pathname.split('/');
  const page = location[3];
  const isPorfolioPage = page === 'portfolio';
  const [t, i18n] = useTranslation('global');
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const closeCreatePortfolioModal = () => {
    setShowCreatePortfolioModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1026);
    };

    window.scrollTo(0, 0);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([getCurrencies(), getLanguages()])
      .then(([currenciesResponse, languagesResponse]) => {
        setCurrencies((currenciesResponse.data || []) as []);
        setLanguages((languagesResponse.data || []) as []);
        if (selectedLanguage)
          i18n.changeLanguage(selectedLanguage.toLowerCase());
      })
      .catch((err) => {
        toast.error('Request Failed. Please try again later :(');
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  function renderCurrencySelect(options: any) {
    return (
      <div className="flex w-25 h-15 mr-3 lg:mr-6">
        <select
          className="focus:outline-none bg-base-100 border border-base-300 rounded-[5px] px-1 focus:border-purple-600"
          name="options"
          defaultValue={selectedCurrency}
          onChange={(e: any) => handleCurrencyChange(e.target.value)}
        >
          {options.length ? (
            options.map((option: any, index: number) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))
          ) : (
            <option value={selectedCurrency}>{selectedCurrency}</option>
          )}
        </select>
      </div>
    );
  }

  function renderLanguageSelect(options: any) {
    return (
      <>
        <div className="flex w-25 lg:h-15 mr-3 lg:mr-6">
          <select
            className="focus:outline-none bg-base-100 border border-base-300 rounded-[5px] px-1 focus:border-purple-600"
            name="options"
            defaultValue={selectedLanguage}
            onChange={(e: any) => handleLanguageChange(e.target.value)}
          >
            {options.length ? (
              options.map((option: any, index: number) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))
            ) : (
              <option value={selectedCurrency}>{selectedCurrency}</option>
            )}
          </select>
        </div>
      </>
    );
  }

  function renderLoadingPlaceholder() {
    return (
      <div className="focus:outline-none border border-gray-300 px-6 py-2 rounded w-25 h-15 mr-6">
        <span className="loading loading-spinner loading-md text-[#8C56E1] "></span>
      </div>
    );
  }

  const handleLanguageChange = (lang: string) => {
    changeLanguage(lang)
      .then(() => {
        setSelectedLanguage(lang);
      })
      .catch((err: any) => console.error(err));
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang.toLowerCase());
  };

  const logout = () => {
    logoutUser();
  };

  return (
    <>
      {
        <CreatePortfolioModal
          isOpen={showCreatePortfolioModal}
          onClose={closeCreatePortfolioModal}
          isMobile={isMobile}
          setPortfolioList={setPortfolioList}
        />
      }
      <div className="flex lg:h-screen">
        {/* Sidebar */}
        {!isMobile && (
          <Transition
            show={isSidebarOpen}
            enter="transition-transform duration-300 ease-out"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform duration-300 ease-in"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className="lg:w-64 p-4 fixed inset-y-0 left-0 transform overflow-y-auto border-r border-info bg-base-300"
          >
            {isSidebarOpen && (
              <div className="flex flex-col justify-center h-full">
                {theme === 'light' ? (
                  <img src={logoLight} width={200} height={200} alt="logo" />
                ) : (
                  <img src={logoDark} width={200} height={200} alt="logo" />
                )}
                <img src={logoBorder} alt="border" />

                <div className="mt-[3rem] flex flex-col">
                  <>
                    <div>
                      <NavLink
                        to="portfolio"
                        onClick={toggleSubmenu}
                        className={`flex justify-between items-center mb-3 p-2 pl-4 rounded-md text-[base-content]-700 hover:bg-accent ${
                          isPorfolioPage ? 'bg-accent' : ''
                        }`}
                      >
                        <div className="flex">
                          <img src={graph} width={16} height={16} className="mr-2" alt="Icon" />
                          {t('nav.link1')}
                        </div>
                        {portfolioList.length > 0 && <div>{submenuOpen ? <NavArrowDown /> : <NavArrowLeft />}</div>}
                      </NavLink>

                      {portfolioList.length > 0 && (
                        <div
                          style={{
                            height:
                              submenuOpen && portfolioList.length <= 10
                                ? `${portfolioList && portfolioList.length * 25}px`
                                : !submenuOpen
                                ? '0px'
                                : '15.5rem',
                          }}
                          className={`flex flex-col ml-[22px] border-l overflow-hidden ${
                            portfolioList.length >= 10 ? 'overflow-y-auto' : ''
                          } transition-height duration-500`}
                        >
                          {/* Render submenu items dynamically from portfolioList */}
                          {portfolioList?.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/app/dashboard/portfolio/${item.id.toLowerCase()}`}
                              className={`mb-1 hover:bg-accent rounded-md transition-all duration-500 flex items-center ${
                                location[4] === item.id.toLowerCase() ? 'bg-accent' : ''
                              }`}
                            >
                              <span className="text-info">&#9472;</span> <span className="truncate">{item.title}</span>
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </div>

                    <NavLink
                      to="pricing"
                      className={`flex items-center mb-3 p-2 pl-4 rounded-md text-[base-content]-700 hover:bg-accent ${
                        page === 'pricing' ? 'bg-accent' : ''
                      }`}
                      style={{
                        marginTop: submenuOpen && portfolioList.length ? '10px' : '',
                      }}
                    >
                      <img src={pricing} width={16} height={16} className="mr-2" alt="Icon" />
                      {t('nav.link2')}
                    </NavLink>
                    <NavLink
                      to="settings"
                      className={`flex items-center mb-3 p-2 pl-4 rounded-md text-[base-content]-700 hover:bg-accent ${
                        page === 'settings' ? 'bg-accent' : ''
                      }`}
                    >
                      <img src={settings} width={16} height={16} className="mr-2" alt="Icon" />
                      {t('nav.link3')}
                    </NavLink>
                    <NavLink
                      to="support"
                      className={`flex items-center mb-3 p-2 pl-4 rounded-md text-[base-content]-700 hover:bg-accent ${
                        page === 'support' ? 'bg-accent' : ''
                      }`}
                    >
                      <img src={support} width={16} height={16} className="mr-2" alt="Icon" />
                      {t('nav.link4')}
                    </NavLink>
                  </>
                </div>
                <div className="mt-auto">
                  <NavLink
                    onClick={logout}
                    to="/app"
                    className="flex items-center mb-3 p-2 pl-4 rounded-md text-[base-content]-700 hover:bg-accent"
                  >
                    <img src={logoutIcon} width={16} height={16} className="mr-2" alt="Icon" />
                    {t('nav.logout')}
                  </NavLink>
                </div>
              </div>
            )}
          </Transition>
        )}
        {!isMobile && (
          <Transition
            show={!isSidebarOpen}
            enter="transition-transform duration-300 ease-out"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform duration-300 ease-in"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className="lg:w-20 p-4 fixed inset-y-0 left-0 transform overflow-y-auto border-r  bg-base-300 border-info"
          >
            <div className="flex flex-col h-full">
              <img className="border-b pb-3" src={logo} width={90} height={90} alt="logo" />
              {!isSidebarOpen && (
                <div className="mt-10 flex flex-col justify-between h-full">
                  <NavLink
                    to="portfolio"
                    className={`flex items-center mb-3 p-2 pl-2 rounded-md text-[base-content]-700 hover:bg-accent ${
                      isPorfolioPage ? 'bg-accent' : ''
                    }`}
                  >
                    <img src={graph} width={40} height={30} alt="Icon" />
                  </NavLink>
                  <NavLink
                    to="pricing"
                    className={`flex items-center mb-3 p-2 pl-2 rounded-md text-[base-content]-700 hover:bg-accent ${
                      page === 'pricing' ? 'bg-accent' : ''
                    }`}
                  >
                    <img src={pricing} width={60} height={60} alt="Icon" />
                  </NavLink>
                  <NavLink
                    to="settings"
                    className={`flex items-center mb-3 p-2 pl-2 rounded-md text-[base-content]-700 hover:bg-accent ${
                      page === 'settings' ? 'bg-accent' : ''
                    }`}
                  >
                    <img src={settings} width={60} height={60} alt="Icon" />
                  </NavLink>
                  <NavLink
                    to="support"
                    className={`flex items-center mb-3 p-2 pl-2 rounded-md text-[base-content]-700 hover:bg-accent ${
                      page === 'support' ? 'bg-accent' : ''
                    }`}
                  >
                    <img src={support} width={60} height={60} alt="Icon" />
                  </NavLink>
                  <div className="mt-auto">
                    <NavLink
                      to="/app"
                      onClick={logout}
                      className="flex items-center mb-3 p-2 pl-2 rounded-md text-[base-content]-700 hover:bg-accent"
                    >
                      <img src={logoutIcon} width={60} height={60} alt="Icon" />
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </Transition>
        )}

        {/* Main Content */}
        <div className={`flex flex-col flex-1 overflow-hidden ${isSidebarOpen ? 'lg:ml-64' : 'lg:ml-20'}`}>
          {/* Top Navbar */}
          <header className="bg-base-300 white shadow-md z-10">
            <div
              className={`flex items-center justify-between lg:p-4 py-${isMobile ? '4' : ''} px-${isMobile ? '6' : ''}`}
            >
              {/* Collapse Button */}
              {!isMobile && (
                <button
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  className="focus:outline-none border border-info mx-5 px-2 py-2 rounded"
                >
                  <img src={sidebarCollapse} alt="Sidebar Toggle" className="w-6 h-6" />
                </button>
              )}

              {isMobile && <img className="mr-4" src={logo} width={50} height={50} alt="logo" />}

              {/* Top Navbar Content */}
              <div className="flex item-center">
                <div className="Lg:border-r lg:pr-4 flex lg:h-10">
                  {!isLoading ? (
                    <>
                      {renderCurrencySelect(currencies)}
                      {renderLanguageSelect(languages)}
                    </>
                  ) : (
                    <>
                      {renderLoadingPlaceholder()}
                      {renderLoadingPlaceholder()}
                    </>
                  )}
                </div>

                {isPorfolioPage && (
                  <button
                    className="item-center focus:outline-none border border-info px-2 py-2 rounded-[5px] w-25 h-15 lg:ml-6  bg-[#8DC63F] text-[#ffffff] animate-pulse "
                    onClick={() => setShowCreatePortfolioModal(true)}
                  >
                    {isMobile ? (
                      <img src={portfolio} alt="portfolio-logo" className="w-8 h-6" />
                    ) : (
                      <span className="flex flex-row items-center pl-3 pr-3">
                        <img src={portfolio} alt="portfolio-logo" className="w-6 h-6 mr-3" />
                        {t('nav.create')}
                      </span>
                    )}
                  </button>
                )}

                {!isMobile && (
                  <p className={`ml-${isPorfolioPage ? '6' : ''} pl-8 pr-8 border-l px-2 py-2`}>
                    {t('nav.greet')} {getUserDataLocally()?.name}!
                  </p>
                )}
              </div>
            </div>
          </header>

          {/* Main Content */}
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-base-100">
            {/* Nested Routes */}
            {/* I want to pass this (setPortfolioList) to my porfolio component */}
            {children}
          </main>
        </div>
      </div>

      {/* Bottom Navigation */}
      <div className="btm-nav lg:hidden bg-base-300">
        <NavLink
          to="portfolio"
          className={`flex items-center border-none rounded-md text-[base-content]-700 hover:bg-accent ${
            isPorfolioPage ? 'bg-accent' : ''
          }`}
        >
          <img src={graph} width={20} height={20} alt="Icon" />
          <span className="btm-nav-label">{t('nav.link1')}</span>
        </NavLink>
        <NavLink
          to="pricing"
          className={`flex items-center border-none rounded-md text-[base-content]-700 hover:bg-accent ${
            page === 'pricing' ? 'bg-accent' : ''
          }`}
        >
          <img src={pricing} width={20} height={20} alt="Icon" />
          <span className="btm-nav-label">{t('nav.link2')}</span>
        </NavLink>
        <NavLink
          to="settings"
          className={`flex items-center border-none rounded-md text-[base-content]-700 hover:bg-accent ${
            page === 'settings' ? 'bg-accent' : ''
          }`}
        >
          <img src={settings} width={20} height={20} alt="Icon" />
          <span className="btm-nav-label">{t('nav.link3')}</span>
        </NavLink>
        <NavLink
          to="support"
          className={`flex items-center border-none rounded-md text-[base-content]-700 hover:bg-accent ${
            page === 'support' ? 'bg-accent' : ''
          }`}
        >
          <img src={support} width={20} height={20} alt="Icon" />
          <span className="btm-nav-label">{t('nav.link4')}</span>
        </NavLink>
      </div>
    </>
  );
};

export default Navbar;
