import { analyzeChartDirection } from "../../utils/commons";
import positive_sign from "../../assets/icons/positive_sign.svg";
import negative_sign from "../../assets/icons/negative_sign.svg";
import { IPortfolioDeals, IPortfolioStocks } from "../../models/portfolio";
import { NavArrowDown } from "iconoir-react";

interface IMobileRow {
  item: IPortfolioDeals | IPortfolioStocks;
  index: number;
  isLoading: boolean;
  showCarouselIndex: number | null;
  setShowCarouselIndex: (x: number | null) => void;
}
const MobileRow = ({
  item,
  index,
  isLoading,
  showCarouselIndex,
  setShowCarouselIndex,
}: IMobileRow) => {
  const chartDirection = analyzeChartDirection(item.chart);

  const handleArrowClick = () => {
    setShowCarouselIndex(showCarouselIndex === index ? null : index);
  };

  return (
    <>
      <td className={`flex flex-row items-center justify-start lg:ml-6`}>
        {isLoading ? (
          <div className="pl-8">
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          </div>
        ) : (
          <div className="flex items-center font-bold">
            <img
              src={item.logo || ""}
              height={25}
              width={25}
              className="mr-3"
              alt=""
            />
            <div className="text-start">{item.name}</div>
          </div>
        )}
      </td>
      <td>
        {isLoading ? (
          <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
        ) : (
          <span className="flex items-center justify-center">
            <img
              src={
                chartDirection === "positive" ? positive_sign : negative_sign
              }
              width={60}
              height={60}
              alt="Chart"
            />
          </span>
        )}
      </td>
      <td>
        {isLoading ? (
          <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
        ) : (
          <>
            <div
              className="flex items-center justify-center"
              onClick={handleArrowClick}
            >
              <NavArrowDown width={25} height={25} />
            </div>
          </>
        )}
      </td>
    </>
  );
};

export default MobileRow;
