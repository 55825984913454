import { useEffect, useState } from "react";

import rate from "../../assets/main/rate.png";
import sharepass from "../../assets/main/sharepass.png";
import commas from "../../assets/main/commas.png";
import left from "../../assets/main/left.png";
import right from "../../assets/main/right.png";
import white_left from "../../assets/main/arrow-left-white.png";
import black_right from "../../assets/main/arrow-right-black.png";
import { useBreakPoint } from "./MainBanner";

const Testimonials: React.FC = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [windowWidth] = useBreakPoint();

  useEffect(() => {
    setIsPrevDisabled(currentTestimonial === 0);
    setIsNextDisabled(currentTestimonial === testimonials.length - 1);
  }, [currentTestimonial]);

  const handlePrevTestimonial = () => {
    setCurrentTestimonial((prev) => {
      const newTestimonial = prev === 0 ? testimonials.length - 1 : prev - 1;
      return newTestimonial;
    });
  };

  const handleNextTestimonial = () => {
    setCurrentTestimonial((prev) => {
      const newTestimonial = prev === testimonials.length - 1 ? 0 : prev + 1;
      return newTestimonial;
    });
  };
  return (
    <div className="small-container">
      {/* Navigation buttons */}
      <div className="navigation">
        <p className="heading">Clients testimonials</p>
        <div className="body">
          <img src={commas} className="commas" />
          <button onClick={handlePrevTestimonial} disabled={isPrevDisabled}>
            {isPrevDisabled ? (
              <img src={left} alt="Arrow-left" className="Arrow-left" />
            ) : (
              <img src={white_left} alt="Arrow-left" className="Arrow-left" />
            )}
          </button>
          <span>{`${currentTestimonial + 1}/6`}</span>
          <button onClick={handleNextTestimonial} disabled={isNextDisabled}>
            {isNextDisabled ? (
              <img
                src={black_right}
                alt="Arrow-right"
                className="Arrow-right"
              />
            ) : (
              <img src={right} alt="Arrow-right" className="Arrow-right" />
            )}
          </button>
        </div>
      </div>
      <div className="testimonial ">
        <div className="testimonial-container">
          <div className="testimonial-body">
            <p className="testimonial-text">
              {testimonials[currentTestimonial].content}
            </p>
            <div className="testimonial-info">
              <img
                src={testimonials[currentTestimonial].profileImage}
                alt="Profile"
                className="profile-image"
              />
              <div className="name-and-stars">
                <p className="name">{testimonials[currentTestimonial].name}</p>
                <img
                  src={testimonials[currentTestimonial].stars}
                  alt="stars"
                  className="stars"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Second testimonial container */}
        {windowWidth >= 500 && testimonials[currentTestimonial + 1] && (
          <div className="testimonial-container">
            <div className="testimonial-body">
              <p className="testimonial-text">
                {testimonials[currentTestimonial + 1].content}
              </p>
              <div className="testimonial-info">
                <img
                  src={testimonials[currentTestimonial + 1].profileImage}
                  alt="Profile"
                  className="profile-image"
                />
                <div className="name-and-stars">
                  <p className="name">
                    {testimonials[currentTestimonial + 1].name}
                  </p>
                  <img
                    src={testimonials[currentTestimonial + 1].stars}
                    alt="stars"
                    className="stars"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;

const testimonials = [
  {
    content:
      "Finberry.io has made my life so much easier. I can now easily track all of my investments in one place and make informed decisions based on real-time data. Highly recommend!",
    name: "John Doe",
    stars: rate,
    profileImage: sharepass,
  },
  {
    content:
      "I have been using Finberry.io for a while now, and it has completely transformed the way I manage my finances. The user-friendly interface and comprehensive analytics make it stand out from other platforms.",
    name: "Jane Smith",
    stars: rate,
    profileImage: sharepass,
  },
  {
    content:
      "Finberry.io offers a seamless experience for managing investments. Its intuitive design and powerful features make it an essential tool for anyone serious about their financial portfolio.",
    name: "Alice Johnson",
    stars: rate,
    profileImage: sharepass,
  },
  {
    content:
      "I was skeptical at first, but Finberry.io exceeded my expectations. The detailed analytics and real-time updates have helped me make better investment decisions and grow my portfolio.",
    name: "Bob Brown",
    stars: rate,
    profileImage: sharepass,
  },
  {
    content:
      "As a professional investor, I need tools that provide accurate insights and actionable data. Finberry.io delivers on both fronts, helping me stay ahead in a competitive market.",
    name: "Emily Wilson",
    stars: rate,
    profileImage: sharepass,
  },
  {
    content:
      "Finberry.io has simplified the way I manage my investments. Its user-friendly interface and powerful analytics have made it my go-to platform for tracking and analyzing financial data.",
    name: "Michael Davis",
    stars: rate,
    profileImage: sharepass,
  },
];
