import { ChangeEvent } from "react";
import searchIcon from "../../assets/icons/search.png";
import columnsImage from "../../assets/icons/columns.png";

const SearchBar = ({ onChange, onClick, isMobile }: ISearchBar) => {
  return (
    <>
      <div
        className={`relative ${
          isMobile ? "" : "border-gray-300 border-r pr-[2.3rem]"
        }`}
      >
        <img
          src={searchIcon}
          alt="Icon"
          className="h-5 w-8 pr-2 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-500 border-r"
        />
        <input
          type="text"
          placeholder="Search"
          className={`input input-bordered pl-12 focus:outline-none focus:border-purple-400 bg-base-300 text-base-content ${
            isMobile ? "" : "w-full"
          }`}
          onChange={onChange}
        />
      </div>
      {isMobile && (
        <>
          <button
            className="flex direction-row items-center border bg-base-300 border-gray-300 px-2 py-1 rounded-[8px] text-base-content"
            onClick={onClick}
          >
            <img src={columnsImage} width={25} height={25} alt="Icon" />
          </button>
        </>
      )}
    </>
  );
};

export default SearchBar;

interface ISearchBar {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  isMobile: Boolean;
}
