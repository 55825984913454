import { analyzeChartDirection } from "../../utils/commons";
import positive_sign from "../../assets/icons/positive_sign.svg";
import negative_sign from "../../assets/icons/negative_sign.svg";
import deleteIcon from "../../assets/icons/delete.png";
import { IPortfolioDeals, IPortfolioStocks } from "../../models/portfolio";

interface IRow {
  item: IPortfolioDeals | IPortfolioStocks;
  index: number;
  headers: string[];
  isLoading: boolean;
  currencySign: string;
  showDeals: boolean;
  deleteDeal: (itemId: string, index: number) => void;
}
const Row = ({
  item,
  index,
  headers,
  isLoading,
  currencySign,
  showDeals,
  deleteDeal,
}: IRow) => {
  const chartDirection = analyzeChartDirection(item.chart);

  return (
    <>
      {headers.includes("Company") && (
        <td className="flex flex-row items-center justify-start">
          {isLoading ? (
            <div className="pl-8">
              <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
            </div>
          ) : (
            <span className="flex items-center font-semibold text-[14px]">
              <img
                src={item.logo || ""}
                width={24}
                height={24}
                className="mr-3"
                alt="Icon"
              />
              {item.name}
            </span>
          )}
        </td>
      )}
      {headers.includes("Chart") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="flex items-center justify-start">
              <img
                src={
                  chartDirection === "positive" ? positive_sign : negative_sign
                }
                width={44}
                height={28}
                alt="Chart"
              />
            </span>
          )}
        </td>
      )}
      {headers.includes("Quantity") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="hover:bg-gray-100 pl-2 flex items-center rounded-[10px] h-[30px] text-[14px]">
              {item.quantity}
            </span>
          )}
        </td>
      )}
      {headers.includes("Average price") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="hover:bg-gray-100 pl-2 flex items-center rounded-[10px] h-[30px] text-[14px]">
              {currencySign}
              {item.averagePrice}
            </span>
          )}
        </td>
      )}
      {headers.includes("Current price") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="hover:bg-gray-100 pl-2 flex items-center rounded-[10px] h-[30px] text-[14px]">
              {currencySign}
              {item.currentPrice}
            </span>
          )}
        </td>
      )}
      {headers.includes("Purchase price") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="hover:bg-gray-100 pl-2 flex items-center rounded-[10px] h-[30px] text-[14px]">
              {currencySign}
              {item.purchasePrice}
            </span>
          )}
        </td>
      )}
      {headers.includes("Dividends") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="hover:bg-gray-100 pl-2 flex items-center rounded-[10px] h-[30px] text-[14px]">
              {item.dividends}
            </span>
          )}
        </td>
      )}
      {headers.includes("Dividends yeild%") && (
        <td>
          {isLoading ? (
            <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
          ) : (
            <span className="hover:bg-gray-100 pl-2 flex items-center rounded-[10px] h-[30px] text-[14px]">
              {item.dividendYieldPercent}%
            </span>
          )}
        </td>
      )}
      {showDeals && (
        <td>
          <span
            className="flex items-center justify-center cursor-pointer"
            onClick={() => deleteDeal(item.id, index)}
            title="Delete Deal"
          >
            <img src={deleteIcon} width={24} height={24} alt="Delete" />
          </span>
        </td>
      )}
    </>
  );
};

export default Row;
