import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import portfolio from '../assets/icons/new_portfolio.svg';
import { createPortfolio, getPortfolioList } from '../api/portfolio';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IPortfolioList } from '../models/portfolio';

interface CreatePortfolioModalProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean;
  setPortfolioList: Dispatch<SetStateAction<IPortfolioList[]>>;
}

const CreatePortfolioModal = ({ isOpen, onClose, isMobile, setPortfolioList }: CreatePortfolioModalProps) => {
  const modalId = 'create_portfolio_modal';
  const [portfolioTitle, setPortfolioTitle] = useState('');
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    if (isOpen) {
      // @ts-ignore
      modalElement?.showModal();
    } else {
      // @ts-ignore
      modalElement?.close();
    }
  }, [isOpen]);

  const handleCreatePortfolio = () => {
    if (!portfolioTitle) return toast.warn('Please enter title');
    createPortfolio(portfolioTitle)
      .then(() => {
        getPortfolioList().then((portfolio) => setPortfolioList(portfolio.data));
        toast.success('Portfolio created successfully');
      })
      .catch(() => toast.error('Error creating portfolio'))
      .finally(() => setPortfolioTitle(''));
    onClose();
  };

  return (
    <dialog id={modalId} className={`modal ${isMobile ? 'modal-bottom' : ''}`}>
      <div className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h3 className="font-bold text-xl text-center">{t('portfolio.createPortfolio')}</h3>
        <div className="py-6 text-left">
          <label htmlFor="portfolioTitle" className="block text-sm font-medium text-gray-700">
            {t('portfolio.title')}
          </label>
          <input
            type="text"
            id="portfolioTitle"
            placeholder={t('portfolio.enterTitle')}
            className="focus:border-purple-600 mt-1 p-2 border rounded-md w-full border border-gray-300 bg-base-200"
            value={portfolioTitle}
            maxLength={60}
            onChange={(e) => setPortfolioTitle(e.target.value)}
          />
        </div>
        <div className={`flex justify-center ${isMobile ? 'flex-col' : ''} gap-2`}>
          <button className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6  bg-[#8C56E1] text-[#ffffff]">
            <span className="flex flex-row item-center justify-center pl-3 pr-3" onClick={handleCreatePortfolio}>
              <img src={portfolio} alt="portfolio-logo" className="w-6 h-6 mr-3" />
              {t('button.create')}
            </span>
          </button>
          <button
            className="item-center focus:outline-none border border-gray-300 px-2 py-2 rounded-[5px] lg:w-32 h-15 lg:ml-6 text-base-content bg-base-300"
            onClick={onClose}
          >
            {t('button.cancel')}
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop" onClick={onClose}></form>
    </dialog>
  );
};

export default CreatePortfolioModal;
