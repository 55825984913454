const ENV = {
  API_BASE_URL: 'https://uat.finberry.io/api',
  GOOGLE_CLIENT_ID: '915552576392-6v1jtd4gv5j6kan26243qij96j468in0.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECRET: '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',
  LINKED_IN_CLIENT_ID: '77db1loavdkgsk',
  LINKED_IN_CLIENT_SECRET: 'xQPEoIc2cFZXYXi0',
  FACEBOOK_APP_ID: '935116694793299',
  FACEBOOK_APP_SECRET: '949d07bdfe2b9e4b9d9f0d5396d37590',
};

export default ENV;
