// PortfolioAssetsTable.tsx
import React from 'react';
import up_arrow from '../../assets/icons/up_arrow.svg';
import down_arrow from '../../assets/icons/down_arrow.svg';
import { IPortfolioAssets } from '../../models/portfolio';
import empty_table from '../../assets/icons/empty_table.png';
import { useTranslation } from 'react-i18next';

interface PortfolioAssetsTableProps {
  data: IPortfolioAssets[];
  isLoading: boolean;
}

const PortfolioAssetsTable = ({ data, isLoading }: PortfolioAssetsTableProps) => {
  const [t, i18n] = useTranslation('global');

  return (
    <div>
      <table className="table bg-base-300 h-30" style={{ borderRadius: data.length > 0 ? '10px' : '15px 15px 0 0',}}>
        {/* head */}
        <thead className="text-gray-400">
          <tr className="text-[14px] text-left !h-[4rem]">
            <th style={{ fontWeight: '100' }}>{t('portfolio.assets')}</th>
            <th style={{ fontWeight: '100' }}>{t('portfolio.currentValue')}</th>
            <th style={{ fontWeight: '100' }}>{t('portfolio.profit')}</th>
            <th style={{ fontWeight: '100' }}>{t('portfolio.pl')}</th>
            <th style={{ fontWeight: '100' }}>{t('portfolio.pl%')}</th>
            <th style={{ fontWeight: '100' }}>{t('portfolio.share')}</th>
          </tr>
        </thead>
        {(data.length > 0 || isLoading) && (
          <tbody className="text-[18px] text-left">
            {data.length > 0 &&
              data.map((row, index) => (
                <tr key={index} className="border-none h-[3.5rem]">
                  <td>
                    {isLoading ? (
                      <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
                    ) : (
                      <span className="hover:bg-gray-100 w-[100%] flex items-center pl-1 py-4 rounded-[4px] h-[40px] font-semibold text-[14px] text-left">
                        {row.name ?? '-'}
                      </span>
                    )}
                  </td>
                  <td>
                    {isLoading ? (
                      <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
                    ) : (
                      <span className="hover:bg-gray-100 w-[100%] flex items-center pl-1 py-4 rounded-[4px] h-[40px] font-semibold text-[14px] text-left">
                        {row.amount ?? '-'}
                      </span>
                    )}
                  </td>
                  <td className="flex flex-row items-center justify-left">
                    {isLoading ? (
                      <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
                    ) : (
                      <span
                        style={{
                          backgroundColor: row.currentProfit >= 0 ? '#F1F8E8' : '#F7D5D9',
                        }}
                        className={`flex items-center  text-[${
                          row.currentProfit >= 0 ? '#8DC63F' : '#D92C3F'
                        }] rounded-[10px] px-4 py-2 text-[14px]`}
                      >
                        <img
                          src={row.currentProfit >= 0 ? up_arrow : down_arrow}
                          width={12}
                          height={12}
                          className="mr-2"
                          alt="Icon"
                        />
                        {row.currentProfit ?? '-'}
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="flex flex-row items-center justify-left">
                      {isLoading ? (
                        <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
                      ) : (
                        <span
                          style={{
                            backgroundColor: row.profitLostPerDay >= 0 ? '#F1F8E8' : '#F7D5D9',
                          }}
                          className={`flex items-center  text-[${
                            row.profitLostPerDay >= 0 ? '#8DC63F' : '#D92C3F'
                          }] rounded-[10px] px-4 py-2 text-[14px]`}
                        >
                          <img
                            src={row.profitLostPerDay >= 0 ? up_arrow : down_arrow}
                            width={12}
                            height={12}
                            className="mr-2"
                            alt="Icon"
                          />
                          {row.profitLostPerDay ?? '-'}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="flex flex-row items-center justify-left">
                    {isLoading ? (
                      <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
                    ) : (
                      <span
                        style={{
                          backgroundColor: row.dividendsYield >= 0 ? '#F1F8E8' : '#F7D5D9',
                        }}
                        className={`flex items-center text-[${
                          row.dividendsYield >= 0 ? '#8DC63F' : '#D92C3F'
                        }] rounded-[10px] px-4 py-2 text-[14px]`}
                      >
                        <img
                          src={row.dividendsYield >= 0 ? up_arrow : down_arrow}
                          width={12}
                          height={12}
                          className="mr-2"
                          alt="Icon"
                        />
                        {row.dividendsYield ?? '-'}
                      </span>
                    )}
                  </td>
                  <td>
                    {isLoading ? (
                      <span className="loading loading-spinner loading-md text-[#8C56E1]"></span>
                    ) : (
                      <span className="hover:bg-gray-100 w-[100%] flex items-center pl-1 py-4 rounded-[4px] h-[40px] font-semibold text-[14px] text-left">
                        {row.dividends ?? '-'}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>

      {/* if table is empty show the empty_table image */}
      {data.length === 0 && !isLoading && (
        <div className="flex justify-center items-center text-center h-25 bg-base-300 rounded-b-lg">
          <img src={empty_table} height={250} width={250} alt="Icon" className="mx-auto my-4" />
        </div>
      )}
    </div>
  );
};

export default PortfolioAssetsTable;
