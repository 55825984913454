import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import global_en from './translations/en/global.json';
import global_ru from './translations/ru/global.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { global: global_en },
    ru: { global: global_ru },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
