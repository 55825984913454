import { AxiosResponse } from 'axios';
import axiosApi from '../utils/axios';
const USER_ENDPOINT = '/user';

export const refreshToken = async (refreshData: any): Promise<any> => {
  try {
    return await axiosApi.post(`${USER_ENDPOINT}/refresh`, refreshData);
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};

export const getUserSettings = async (): Promise<any> => {
  try {
    const response = await axiosApi.get(`${USER_ENDPOINT}/settings`);
    return response.data;
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};

export const setUserSettings = async (themeData: any): Promise<any> => {
  try {
    return await axiosApi.post(`${USER_ENDPOINT}/settings`, themeData);
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};

export const updatePassword = async (passData: any): Promise<any> => {
  try {
    return await axiosApi.post(`${USER_ENDPOINT}/password`, passData);
  } catch (error) {
    console.error('API Request failed:', error);
    throw error;
  }
};

export const changeLanguage = async (Language: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.post(`${USER_ENDPOINT}/language`, {
      Language,
    });
  } catch (error) {
    console.error('Error adding deal', error);
    throw error;
  }
};

export const changeCurrency = async (Currency: string): Promise<AxiosResponse<any>> => {
  try {
    return await axiosApi.post(`${USER_ENDPOINT}/currency`, {
      Currency,
    });
  } catch (error) {
    console.error('Error adding deal', error);
    throw error;
  }
};
