import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IPortfolioDeals, IPortfolioStocks } from '../../models/portfolio';
import empty_table from '../../assets/icons/empty_table.png';
import '../../styles/table.scss';
import positive_sign from '../../assets/icons/positive_sign.svg';
import search from '../../assets/icons/search.png';
import columns from '../../assets/icons/columns.png';
import negative_sign from '../../assets/icons/negative_sign.svg';
import deleteIcon from '../../assets/icons/delete.png';
import { NavArrowDown, NavArrowLeft, NavArrowRight } from 'iconoir-react';

interface GenericTableProps<T> {
  data: IPortfolioDeals[] | IPortfolioStocks[];
  headers: string[];
  renderRow: (item: IPortfolioDeals | IPortfolioStocks, index: number) => React.ReactNode;
  dealsHeader?: string;
  isLoading: boolean;
  isMobile: boolean;
  isDeals: boolean;
  showCarouselIndex: number | null;
  setShowDeleteDealModal: Dispatch<SetStateAction<boolean>>;
  setDeleteDealIndex: Dispatch<SetStateAction<number>>;
  setDeleteDealId: Dispatch<SetStateAction<string>>;
  currencySign: string;
  allHeaders: string[];
}

const GenericTable = <T,>({
  data,
  headers,
  renderRow,
  dealsHeader,
  isLoading,
  isMobile,
  isDeals,
  showCarouselIndex,
  setShowDeleteDealModal,
  setDeleteDealIndex,
  setDeleteDealId,
  currencySign,
  allHeaders,
}: GenericTableProps<T>) => {
  const extendedHeaders = dealsHeader ? [...headers, '---'] : headers;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowVisibility, setRowVisibility] = useState({
    Quantity: true,
    'Average price': true,
    'Current price': true,
    'Purchase price': true,
    Dividends: true,
    'Dividends yeild%': true,
  });

  useEffect(() => {
    const updatedVisibility = {
      Quantity: allHeaders.includes('Quantity'),
      'Average price': allHeaders.includes('Average price'),
      'Current price': allHeaders.includes('Current price'),
      'Purchase price': allHeaders.includes('Purchase price'),
      Dividends: allHeaders.includes('Dividends'),
      'Dividends yeild%': allHeaders.includes('Dividends yeild%'),
    };

    setRowVisibility(updatedVisibility);
  }, [allHeaders]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <table
        className="table border-l border-b border-r  bg-base-300 h-30"
        style={{
          borderRadius: data.length === 0 ? '0 15px 0 0' : '0 10px 0 0',
        }}
      >
        <thead className="text-gray-400">
          <tr className="text-[20px] text-center h-[4rem]">
            {extendedHeaders.map((header, index) => (
              <th
                key={index}
                className="text-[14px]"
                style={{
                  paddingLeft: isMobile ? '' : '10px',
                  fontWeight: '100',
                  textAlign: 'left',
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        {(currentItems.length > 0 || isLoading) && (
          <tbody className="text-[18px] text-center">
            {!isMobile &&
              currentItems.length > 0 &&
              currentItems.map((item, index) => (
                <tr key={index} className="border-none h-[3.5rem]">
                  {renderRow(item, index)}
                </tr>
              ))}
            {isMobile &&
              currentItems.length > 0 &&
              currentItems.map((item, index) => (
                <>
                  <tr key={index} className="border-none h-[3.5rem]">
                    {renderRow(item, index)}
                  </tr>
                  <tr>
                    {showCarouselIndex === index && (
                      //UPDATE STYLING HERE
                      <td colSpan={3} className="pl-4 pr-6">
                        {rowVisibility['Quantity'] && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Quantity</span>
                            <span>{item.quantity}</span>
                          </div>
                        )}
                        {rowVisibility['Average price'] && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Average Price</span>
                            <span>
                              {currencySign}
                              {item.averagePrice}
                            </span>
                          </div>
                        )}
                        {rowVisibility['Current price'] && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Current Price</span>
                            <span>
                              {currencySign}
                              {item.currentPrice}
                            </span>
                          </div>
                        )}
                        {rowVisibility['Purchase price'] && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Purchase Price</span>
                            <span>
                              {currencySign}
                              {item.purchasePrice}
                            </span>
                          </div>
                        )}
                        {rowVisibility['Dividends'] && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Dividends</span>
                            <span>{item.dividends}</span>
                          </div>
                        )}
                        {rowVisibility['Dividends yeild%'] && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Dividend Yield %</span>
                            <span>{item.dividendYieldPercent.toFixed(2)}%</span>
                          </div>
                        )}

                        {/* DELETE FOR DEALS */}
                        {isDeals && (
                          <div className="w-full flex justify-between pb-2">
                            <span className="text-accent">Delete</span>
                            <span
                              className="flex items-center justify-center cursor-pointer"
                              onClick={() => {
                                setShowDeleteDealModal(true);
                                setDeleteDealIndex(index);
                                setDeleteDealId(item.id);
                              }}
                              title="Delete Deal"
                            >
                              <img src={deleteIcon} width={24} height={24} alt="Delete" />
                            </span>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                </>
              ))}
          </tbody>
        )}
      </table>

      {/* if table is empty show the empty_table image */}
      {data.length === 0 && !isLoading && (
        <div className="flex justify-center items-center text-center h-25 bg-base-300 rounded-b-lg">
          <img src={empty_table} height={250} width={250} alt="Icon" className="mx-auto my-4" />
        </div>
      )}

      {!isMobile ? (
        <div className="action-bar bg-base-300 border border-gray-200">
          <div className="pagination ">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="text-base-content"
            >
              <NavArrowLeft /> Prev
            </button>
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? 'active text-white' : 'text-base-content'}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="text-base-content"
            >
              Next <NavArrowRight />
            </button>

            <span className="page-info">
              {currentPage}-{currentPage + 9} of {totalPages} Pages
            </span>
          </div>

          <div className="page-size">
            <label htmlFor="page-size-select">Per Page</label>
            <select
              id="page-size-select"
              onChange={(e) => {
                const newSize = parseInt(e.target.value);
                setCurrentPage(1);
                setItemsPerPage(newSize);
              }}
              className="text-base-content"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="60">60</option>
              <option value="70">70</option>
              <option value="80">80</option>
            </select>
          </div>
        </div>
      ) : (
        totalPages > 1 && (
          <div
            className="show-more flex justify-center items-center bg-base-300 py-3 text-gray-300"
            onClick={() => setItemsPerPage(itemsPerPage + 10)}
          >
            Show More <NavArrowDown />
          </div>
        )
      )}
    </>
  );
};

export default GenericTable;
