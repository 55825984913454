import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes/Routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ENV from './environment';

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <GoogleOAuthProvider clientId={ENV.GOOGLE_CLIENT_ID}>
        <AppRoutes />
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
