import { useState } from "react";

import illustration from "../../assets/main/illustration.png";
import illustration02 from "../../assets/main/illustration02.png";
import illustration03 from "../../assets/main/illustration03.png";

const TitleAndBar: React.FC = () => {
  const [activeTitle, setActiveTitle] = useState(0);
  const handleTitleClick = (index: number) => {
    setActiveTitle(index);
  };
  return (
    <div className="flex justify-between mt-5">
      {/* Title and Bar Container */}
      <div className="container-main hidden lg:flex">
        {/* Title Container */}
        <div className="left-container">
          {/* Vertical Bar */}
          <div
            className={`bar ${activeTitle !== null ? "bar-active" : ""}`}
          ></div>
          <div className="title-container">
            <div onClick={() => handleTitleClick(0)}>
              <div
                className={
                  activeTitle === 0 ? "title-active" : "title-inactive"
                }
              >
                Streamline Your Deal Pipeline
              </div>
              <div
                className={
                  activeTitle === 0 ? "subtitle-active" : "subtitle-inactive"
                }
              >
                Adding a deal - file upload or manually
              </div>
            </div>
            <div onClick={() => handleTitleClick(1)}>
              <div
                className={
                  activeTitle === 1 ? "title-active" : "title-inactive"
                }
              >
                Simplify Your Analytics
              </div>
              <div
                className={
                  activeTitle === 1 ? "subtitle-active" : "subtitle-inactive"
                }
              >
                User-friendly interface - only the necessary reports and
                statistics
              </div>
            </div>
            <div onClick={() => handleTitleClick(2)}>
              <div
                className={
                  activeTitle === 2 ? "title-active" : "title-inactive"
                }
              >
                Focus on the Essentials
              </div>
              <div
                className={
                  activeTitle === 2 ? "subtitle-active" : "subtitle-inactive"
                }
              >
                Only necessary and understandable indicators
              </div>
            </div>
          </div>
        </div>
        {/* Image Container */}
        <div className="right-container">
          <img src={illustration} alt="illustration" className="image" />
        </div>
      </div>

      {/* Mobile */}
      <div className="lg:hidden">
        <div className="carousel w-full mt-5">
          <div
            id="item1"
            className="carousel-item relative flex flex-col items-center w-[100%]"
          >
            <img src={illustration} alt="illustration" />
            <div className="image-info-mobile">
              <div className="image-title-mobile">
                Streamline Your Deal Pipeline
              </div>
              <div className="subtitle-mobile">
                Adding a deal - file upload or manually
              </div>
            </div>
          </div>

          <div
            id="item2"
            className="carousel-item relative flex flex-col items-center w-[100%]"
          >
            <img src={illustration02} alt="illustration" />
            <div className="image-info-mobile">
              <div className="image-title-mobile">Simplify Your Analytics</div>
              <div className="subtitle-mobile">
                User-friendly interface - only the necessary reports and
                statistics
              </div>
            </div>
          </div>

          <div
            id="item3"
            className="carousel-item relative flex flex-col items-center w-[100%]"
          >
            <img src={illustration03} alt="illustration" />
            <div className="image-info-mobile">
              <div className="image-title-mobile">Focus on the Essentials</div>
              <div className="subtitle-mobile">
                Only necessary and understandable indicators
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleAndBar;
